@import "../variables";

.clinic-hero {
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 140px;
  padding-bottom: 50px;
  background: url(../../../public/images/lab-service.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  position: relative;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #00000099;
  }
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: $white-color;
    z-index: 3;
    gap: 20px;
    text-align: center;

    .logo-container {
      background-color: $white-color;
      padding: 10px;
      border-radius: 10px;
    }

    h2 {
      font-size: 32px;
    }
    h3 {
      font-size: 24px;
      font-weight: 500;
    }
  }
}

.clinic-services {
  .container {
    display: flex;
    padding-block: 100px;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;

    .clinic-card {
      max-width: 600px;
      width: 100%;
      display: flex;
      gap: 20px;
      background-color: #fbfbfb;
      padding: 30px;
      border-radius: 12px;
      img {
        height: 100%;
        width: 200px;
        border-radius: 16px;
        object-fit: cover;
      }
      .col {
        display: flex;
        flex-direction: column;
        gap: 10px;

        h3 {
          font-size: 24px;
          font-weight: 600;
          color: #b2670b;
        }
        p {
          line-height: 26px;
          color: #686868;
          font-size: 16px;
        }
      }
    }
  }
}

.clinicians {
  .container {
    padding-bottom: 100px;
    display: flex;
    flex-direction: column;
    gap: 100px;

    h1 {
      font-size: 32px;
      text-align: center;

      span {
        color: #b2670b;
      }
    }

    .row {
      display: flex;
      gap: 50px;
      align-items: center;

      .col {
        display: flex;
        flex-direction: column;
      }
      > .col {
        gap: 30px;
        min-width: 300px;
        p {
          line-height: 26px;
          font-size: 18px;
          color: #5d5d5d;
        }

        .col {
          gap: 10px;

          h3 {
            font-size: 18px;
            font-weight: 500;
          }

          span {
            color: #8e8e8e;
            font-size: 16px;
          }
        }
      }

      .img-container {
        margin-left: 50px;
        position: relative;

        &::before {
          display: block;
          content: "";
          position: absolute;
          height: 250px;
          width: 50%;
          background-color: $primary-color;
          top: -50px;
          left: -50px;
          z-index: -1;

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
}

.highlighted-services {
  .container {
    padding-block: 100px;
    display: flex;
    flex-direction: column;
    gap: 100px;
    h1 {
      font-size: 32px;
      text-align: center;

      span {
        color: #b2670b;
      }
    }
    .row {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 30px;

      .higlighted-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        img {
          height: 100px;
          width: 120px;
        }
        p {
          text-align: center;
          font-size: 18px;
          line-height: 26px;
        }

        h3 {
          font-size: 26px;
          font-weight: 500;
        }
      }
    }
  }
}
.contact-information {
  .container {
    padding-bottom: 100px;
    gap: 50px;
    display: flex;
    flex-direction: column;

    h1 {
      text-align: center;
      font-size: 32px;

      span {
        color: #b2670b;
      }
    }

    > .row {
      justify-content: center;
      gap: 50px;

      .map-container {
        height: 200px;
        width: 400px;

        iframe {
          height: 100%;
          width: 100%;
          outline: none;
          border: none;
        }
      }

      > .col {
        gap: 20px;
        .row {
          justify-content: start;
          gap: 20px;

          .icon-container {
            background-color: #f3f3f3;
            padding: 12px 15px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 60px;
            .icon {
              color: $primary-color;
              font-size: 25px;
            }
          }

          .col {
            gap: 10px;

            span {
              font-size: 14px;
              color: #b5b5b5;
            }

            h4 {
              font-size: 20px;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1430px) {
  .clinic-services {
    .container {
      .clinic-card {
        max-width: 100%;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .clinicians {
    .container {
      .row {
        flex-direction: column;
      }
    }
  }

  .highlighted-services {
    .container {
      .row {
        grid-template-columns: 1fr;
        place-items: center;

        .higlighted-card {
          max-width: 500px;
        }
      }
    }
  }

  .contact-information {
    .container {
      > .row {
        flex-direction: column;

        .map-container {
          width: 100%;
          height: 300px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .clinic-services {
    .container {
      .clinic-card {
        flex-direction: column;
        padding: 10px;

        img {
          width: 100%;
          height: 200px;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .clinicians {
    .container {
      .row {
        .img-container {
          img {
            height: 250px;
          }

          &::before {
            height: 200px;
            top: -30px;
            left: -30px;
          }
        }
      }
    }
  }
}
