@import "../variables";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.popup-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99998;
  background-color: rgba(0, 0, 0, 0.378);
  cursor: pointer;
}

.job-popup {
  max-width: 800px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 60vh;
  z-index: 99999;
  border-radius: 8px;
  padding: 30px;
  overflow: hidden;
  padding-bottom: 30px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: $white-color;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);

  .x-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 30px;
    cursor: pointer;
  }

  .apply-now {
    padding: 10px;
    border: 1px solid $primary-color;
    color: $primary-color;
    width: 150px;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  h2 {
    font-size: 18px;
    font-weight: 400;
    color: $primary-color;
  }

  .row-2 {
    display: flex;
    align-items: center;
    gap: 30px;

    .icon-text {
      display: flex;
      gap: 10px;
      align-items: center;

      p {
        font-weight: 400;
      }
    }

    p {
      margin: 0;
      color: #000000;
      font-family: poppins;
      font-size: 16px;
      font-weight: bolder;
    }

    .location,
    .clock {
      font-size: 20px;
      color: $primary-color;
    }
  }

  .description {
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    overflow: auto;
    height: 80%;

    li {
      list-style-type: disc;
      margin-left: 1rem;
      display: list-item;
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(100, 152, 237, 0.558);
    }

    &::-webkit-scrollbar-thumb {
      background: $primary-color;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: $primary-color;
    }
  }
}
.main-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  hr {
    color: #606060;
  }

  .data {
    .data-card-container {
      display: flex;
      flex-direction: column;
      gap: 25px;

      > p {
        font-size: 30px;
        color: $primary-color;
        font-weight: bold;
      }
    }
    .career-card {
      display: flex;
      flex-direction: column;
      gap: 25px;

      .arrow {
        h3 {
          font-family: poppins;
          font-size: 20px;
          color: #000000;
        }
      }

      .row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 20px;

        .col-1 {
          display: flex;
          flex-direction: column;
          gap: 30px;
          width: 90%;
          h3 {
            font-size: 30px;
            font-weight: 400;
          }
          .description {
            font-family: poppins;
            font-size: 18px;
            gap: 10px;
            color: #606060;

            display: -webkit-box;
            max-width: 100%;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            overflow: hidden;
            position: relative;
          }

          .row-2 {
            display: flex;
            align-items: center;
            gap: 30px;

            .icon-text {
              display: flex;
              gap: 10px;
              align-items: center;

              p {
                font-weight: 400;
              }
            }

            p {
              margin: 0;
              color: #000000;
              font-family: poppins;
              font-size: 16px;
              font-weight: bolder;
            }

            .location,
            .clock {
              font-size: 20px;
              color: $primary-color;
            }
          }
        }
        .col-2 {
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 20px;
          color: $primary-color;

          .apply-now {
            padding: 10px;
            border: 1px solid $primary-color;
            color: $primary-color;
            width: 150px;
            display: flex;
            align-items: center;
            gap: 10px;
            text-decoration: none;
          }

          span {
            cursor: pointer;
            font-weight: 600;
          }
        }
      }
    }
  }

  .next-move,
  .our-form {
    .container {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(211, 227, 253, 0.44);
      flex-direction: column;
      padding-block: 100px;
      gap: 30px;
      text-align: center;

      h1 {
        font-size: 48px;
        font-weight: 400;
        color: $primary-color;
      }

      p {
        font-size: 18px;
        line-height: 26px;
      }

      .pink-bg-link {
        background-color: transparent;
        border: 1px solid $primary-color;

        span {
          color: $primary-color;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .job-popup {
    max-width: 80%;
    width: 100%;
  }
}

@media screen and (max-width: 720px) {
  .main-container {
    .team-career {
      .title {
        padding-inline: 25px;
      }
      .image-text {
        .PinkBgBtn {
          display: none;
        }
      }
    }

    // .data {
    //   .career-card {
    //     padding-inline: 25px;

    //     .row-1 {
    //       display: flex;
    //       flex-direction: column;
    //       gap: 20px;

    //       .row-2 {
    //         margin-top: 60px;

    //         .icon-text {
    //           display: flex;
    //           gap: 18px;
    //         }
    //       }
    //     }
    //   }
    // }
  }
}

@media screen and (max-width: 600px) {
  .main-container {
    .data {
      .career-card {
        .row {
          flex-direction: column;

          .col-1 {
            width: 100%;
          }

          .col-2 {
            flex-direction: row;
            justify-content: start;
            align-items: center;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 540px) {
  .main-container {
    .team-career {
      .career-main-container {
        width: 100%;
      }
    }
    .data {
      .career-card {
        .row-1 {
          .paragaph {
            p {
              padding-right: 0px;
            }
          }
          .row-2 {
            margin-top: 10px;
            gap: 8px;

            .icon-text {
              gap: 5px;
            }
            .location {
              font-size: 24px;
            }
            .clock {
              font-size: 24px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .main-container {
    .team-career {
      .career-main-container {
        .pink-bg-link {
          padding: 10px 30px;
        }
      }
    }
  }
}
