@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.popup-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99998;
  background-color: rgba(0, 0, 0, 0.378);
  cursor: pointer;
}

.job-popup {
  max-width: 800px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 60vh;
  z-index: 99999;
  border-radius: 8px;
  padding: 30px;
  overflow: hidden;
  padding-bottom: 30px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}
.job-popup .x-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30px;
  cursor: pointer;
}
.job-popup .apply-now {
  padding: 10px;
  border: 1px solid rgb(42, 129, 232);
  color: rgb(42, 129, 232);
  width: 150px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.job-popup h2 {
  font-size: 18px;
  font-weight: 400;
  color: rgb(42, 129, 232);
}
.job-popup .row-2 {
  display: flex;
  align-items: center;
  gap: 30px;
}
.job-popup .row-2 .icon-text {
  display: flex;
  gap: 10px;
  align-items: center;
}
.job-popup .row-2 .icon-text p {
  font-weight: 400;
}
.job-popup .row-2 p {
  margin: 0;
  color: #000000;
  font-family: poppins;
  font-size: 16px;
  font-weight: bolder;
}
.job-popup .row-2 .location,
.job-popup .row-2 .clock {
  font-size: 20px;
  color: rgb(42, 129, 232);
}
.job-popup .description {
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  overflow: auto;
  height: 80%;
}
.job-popup .description li {
  list-style-type: disc;
  margin-left: 1rem;
  display: list-item;
}
.job-popup .description::-webkit-scrollbar {
  width: 5px;
}
.job-popup .description::-webkit-scrollbar-track {
  background: rgba(100, 152, 237, 0.558);
}
.job-popup .description::-webkit-scrollbar-thumb {
  background: rgb(42, 129, 232);
}
.job-popup .description::-webkit-scrollbar-thumb:hover {
  background: rgb(42, 129, 232);
}

.main-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.main-container hr {
  color: #606060;
}
.main-container .data .data-card-container {
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.main-container .data .data-card-container > p {
  font-size: 30px;
  color: rgb(42, 129, 232);
  font-weight: bold;
}
.main-container .data .career-card {
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.main-container .data .career-card .arrow h3 {
  font-family: poppins;
  font-size: 20px;
  color: #000000;
}
.main-container .data .career-card .row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}
.main-container .data .career-card .row .col-1 {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 90%;
}
.main-container .data .career-card .row .col-1 h3 {
  font-size: 30px;
  font-weight: 400;
}
.main-container .data .career-card .row .col-1 .description {
  font-family: poppins;
  font-size: 18px;
  gap: 10px;
  color: #606060;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  position: relative;
}
.main-container .data .career-card .row .col-1 .row-2 {
  display: flex;
  align-items: center;
  gap: 30px;
}
.main-container .data .career-card .row .col-1 .row-2 .icon-text {
  display: flex;
  gap: 10px;
  align-items: center;
}
.main-container .data .career-card .row .col-1 .row-2 .icon-text p {
  font-weight: 400;
}
.main-container .data .career-card .row .col-1 .row-2 p {
  margin: 0;
  color: #000000;
  font-family: poppins;
  font-size: 16px;
  font-weight: bolder;
}
.main-container .data .career-card .row .col-1 .row-2 .location,
.main-container .data .career-card .row .col-1 .row-2 .clock {
  font-size: 20px;
  color: rgb(42, 129, 232);
}
.main-container .data .career-card .row .col-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  color: rgb(42, 129, 232);
}
.main-container .data .career-card .row .col-2 .apply-now {
  padding: 10px;
  border: 1px solid rgb(42, 129, 232);
  color: rgb(42, 129, 232);
  width: 150px;
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
}
.main-container .data .career-card .row .col-2 span {
  cursor: pointer;
  font-weight: 600;
}
.main-container .next-move .container,
.main-container .our-form .container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(211, 227, 253, 0.44);
  flex-direction: column;
  padding-block: 100px;
  gap: 30px;
  text-align: center;
}
.main-container .next-move .container h1,
.main-container .our-form .container h1 {
  font-size: 48px;
  font-weight: 400;
  color: rgb(42, 129, 232);
}
.main-container .next-move .container p,
.main-container .our-form .container p {
  font-size: 18px;
  line-height: 26px;
}
.main-container .next-move .container .pink-bg-link,
.main-container .our-form .container .pink-bg-link {
  background-color: transparent;
  border: 1px solid rgb(42, 129, 232);
}
.main-container .next-move .container .pink-bg-link span,
.main-container .our-form .container .pink-bg-link span {
  color: rgb(42, 129, 232);
}

@media screen and (max-width: 900px) {
  .job-popup {
    max-width: 80%;
    width: 100%;
  }
}
@media screen and (max-width: 720px) {
  .main-container .team-career .title {
    padding-inline: 25px;
  }
  .main-container .team-career .image-text .PinkBgBtn {
    display: none;
  }
}
@media screen and (max-width: 600px) {
  .main-container .data .career-card .row {
    flex-direction: column;
  }
  .main-container .data .career-card .row .col-1 {
    width: 100%;
  }
  .main-container .data .career-card .row .col-2 {
    flex-direction: row;
    justify-content: start;
    align-items: center;
  }
}
@media screen and (max-width: 540px) {
  .main-container .team-career .career-main-container {
    width: 100%;
  }
  .main-container .data .career-card .row-1 .paragaph p {
    padding-right: 0px;
  }
  .main-container .data .career-card .row-1 .row-2 {
    margin-top: 10px;
    gap: 8px;
  }
  .main-container .data .career-card .row-1 .row-2 .icon-text {
    gap: 5px;
  }
  .main-container .data .career-card .row-1 .row-2 .location {
    font-size: 24px;
  }
  .main-container .data .career-card .row-1 .row-2 .clock {
    font-size: 24px;
  }
}
@media screen and (max-width: 360px) {
  .main-container .team-career .career-main-container .pink-bg-link {
    padding: 10px 30px;
  }
}/*# sourceMappingURL=careers.css.map */