@import "../variables";

.appointment-section {
  padding-block: 70px;
  .container {
    display: flex;
    justify-content: space-between;
    gap: 50px;

    .form-container {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 50%;

      h2 {
        font-size: 32px;
        span {
          color: $brown-color;
        }
      }
      p {
        font-size: 18px;
        line-height: 26px;
      }

      form {
        display: flex;
        flex-direction: column;
        gap: 20px;

        label {
          font-size: 18px;
          color: rgba(122, 109, 109, 1);
        }
        select {
          width: 100%;
        }

        input,
        select {
          padding: 16px;
          font-family: $font-family;
          font-size: 18px;
          color: rgba(175, 175, 175, 1);
        }

        .col {
          display: flex;
          flex-direction: column;
          gap: 10px;
          width: 100%;

          input {
            width: 100%;
          }
        }
        .row {
          display: flex;
          gap: 20px;

          input {
            width: 100%;
          }
        }
        button {
          width: fit-content;
          padding: 12px 24px;
          color: $white-color;
          background-color: $primary-color;
          border: none;
          outline: none;
          font-size: 18px;
          font-weight: 500;
        }
      }
    }
    .img-container {
      width: 50%;
      height: 500px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .appointment-section {
    .container {
      flex-direction: column;

      .form-container {
        width: 100%;

        form {
          .row {
            flex-direction: column;

            input {
              width: 100%;
            }
          }
        }
      }
      .img-container {
        width: 100%;
      }
    }
  }
}
