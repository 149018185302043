@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
.clinic-hero {
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 140px;
  padding-bottom: 50px;
  background: url(../../../public/images/lab-service.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  position: relative;
}
.clinic-hero::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
}
.clinic-hero .container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #ffffff;
  z-index: 3;
  gap: 20px;
  text-align: center;
}
.clinic-hero .container .logo-container {
  background-color: #ffffff;
  padding: 10px;
  border-radius: 10px;
}
.clinic-hero .container h2 {
  font-size: 32px;
}
.clinic-hero .container h3 {
  font-size: 24px;
  font-weight: 500;
}

.clinic-services .container {
  display: flex;
  padding-block: 100px;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}
.clinic-services .container .clinic-card {
  max-width: 600px;
  width: 100%;
  display: flex;
  gap: 20px;
  background-color: #fbfbfb;
  padding: 30px;
  border-radius: 12px;
}
.clinic-services .container .clinic-card img {
  height: 100%;
  width: 200px;
  border-radius: 16px;
  -o-object-fit: cover;
     object-fit: cover;
}
.clinic-services .container .clinic-card .col {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.clinic-services .container .clinic-card .col h3 {
  font-size: 24px;
  font-weight: 600;
  color: #b2670b;
}
.clinic-services .container .clinic-card .col p {
  line-height: 26px;
  color: #686868;
  font-size: 16px;
}

.clinicians .container {
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  gap: 100px;
}
.clinicians .container h1 {
  font-size: 32px;
  text-align: center;
}
.clinicians .container h1 span {
  color: #b2670b;
}
.clinicians .container .row {
  display: flex;
  gap: 50px;
  align-items: center;
}
.clinicians .container .row .col {
  display: flex;
  flex-direction: column;
}
.clinicians .container .row > .col {
  gap: 30px;
  min-width: 300px;
}
.clinicians .container .row > .col p {
  line-height: 26px;
  font-size: 18px;
  color: #5d5d5d;
}
.clinicians .container .row > .col .col {
  gap: 10px;
}
.clinicians .container .row > .col .col h3 {
  font-size: 18px;
  font-weight: 500;
}
.clinicians .container .row > .col .col span {
  color: #8e8e8e;
  font-size: 16px;
}
.clinicians .container .row .img-container {
  margin-left: 50px;
  position: relative;
}
.clinicians .container .row .img-container::before {
  display: block;
  content: "";
  position: absolute;
  height: 250px;
  width: 50%;
  background-color: rgb(42, 129, 232);
  top: -50px;
  left: -50px;
  z-index: -1;
}
.clinicians .container .row .img-container::before img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.highlighted-services .container {
  padding-block: 100px;
  display: flex;
  flex-direction: column;
  gap: 100px;
}
.highlighted-services .container h1 {
  font-size: 32px;
  text-align: center;
}
.highlighted-services .container h1 span {
  color: #b2670b;
}
.highlighted-services .container .row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 30px;
}
.highlighted-services .container .row .higlighted-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.highlighted-services .container .row .higlighted-card img {
  height: 100px;
  width: 120px;
}
.highlighted-services .container .row .higlighted-card p {
  text-align: center;
  font-size: 18px;
  line-height: 26px;
}
.highlighted-services .container .row .higlighted-card h3 {
  font-size: 26px;
  font-weight: 500;
}

.contact-information .container {
  padding-bottom: 100px;
  gap: 50px;
  display: flex;
  flex-direction: column;
}
.contact-information .container h1 {
  text-align: center;
  font-size: 32px;
}
.contact-information .container h1 span {
  color: #b2670b;
}
.contact-information .container > .row {
  justify-content: center;
  gap: 50px;
}
.contact-information .container > .row .map-container {
  height: 200px;
  width: 400px;
}
.contact-information .container > .row .map-container iframe {
  height: 100%;
  width: 100%;
  outline: none;
  border: none;
}
.contact-information .container > .row > .col {
  gap: 20px;
}
.contact-information .container > .row > .col .row {
  justify-content: start;
  gap: 20px;
}
.contact-information .container > .row > .col .row .icon-container {
  background-color: #f3f3f3;
  padding: 12px 15px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
}
.contact-information .container > .row > .col .row .icon-container .icon {
  color: rgb(42, 129, 232);
  font-size: 25px;
}
.contact-information .container > .row > .col .row .col {
  gap: 10px;
}
.contact-information .container > .row > .col .row .col span {
  font-size: 14px;
  color: #b5b5b5;
}
.contact-information .container > .row > .col .row .col h4 {
  font-size: 20px;
  font-weight: 500;
}

@media screen and (max-width: 1430px) {
  .clinic-services .container .clinic-card {
    max-width: 100%;
  }
}
@media screen and (max-width: 900px) {
  .clinicians .container .row {
    flex-direction: column;
  }
  .highlighted-services .container .row {
    grid-template-columns: 1fr;
    place-items: center;
  }
  .highlighted-services .container .row .higlighted-card {
    max-width: 500px;
  }
  .contact-information .container > .row {
    flex-direction: column;
  }
  .contact-information .container > .row .map-container {
    width: 100%;
    height: 300px;
  }
}
@media screen and (max-width: 600px) {
  .clinic-services .container .clinic-card {
    flex-direction: column;
    padding: 10px;
  }
  .clinic-services .container .clinic-card img {
    width: 100%;
    height: 200px;
  }
}
@media screen and (max-width: 500px) {
  .clinicians .container .row .img-container img {
    height: 250px;
  }
  .clinicians .container .row .img-container::before {
    height: 200px;
    top: -30px;
    left: -30px;
  }
}/*# sourceMappingURL=clinic.css.map */