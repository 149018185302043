@import "../variables";

.navigation {
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;

  .patient-info {
    .down-arrow {
      color: white;
    }
    .patient-info-popup {
      padding-top: 0;
      top: 25px;
    }
  }
  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-block: 20px;

    .logo {
      display: flex;
      align-items: center;
      gap: 20px;
      text-decoration: none;

      img {
        height: 70px;
        width: 70px;
      }

      h1 {
        text-transform: uppercase;
        font-size: 16px;
        color: $white-color;
      }
    }

    ul {
      display: flex;
      gap: 20px;
      align-items: center;
      li {
        .link {
          color: $white-color;
          display: flex;
          align-items: center;
          font-size: 15px;
          transition: 0.3s ease;

          &:hover {
            color: $primary-color;
          }
        }
        .clinic {
          border-radius: 20px;
          background-color: $primary-color;
          padding: 15px 20px;
          color: $white-color;
          text-decoration: none;
          transition: 0.3s ease;
          display: block;
          width: fit-content;
          margin-left: 10px;

          &:hover {
            padding: 20px;
          }
        }
      }
    }

    .hamburger-container,
    .close-hamburger-container {
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      display: none;

      .hamburger {
        font-size: 40px;
        color: $white-color;
      }
      .close-hamburger {
        font-size: 40px;
        color: $white-color;
      }
    }
  }
}

.home-hero {
  background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.52),
      rgba(0, 0, 0, 0.53)
    ),
    url(/public/images/home-hero.jpg);
  min-height: 100vh;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  position: relative;
  background-size: cover;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #00000056;
  }

  .container {
    padding-bottom: 50px;
    padding-top: 100px;
    display: flex;
    justify-content: center;

    .content {
      color: $white-color;
      max-width: 950px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      position: relative;
      z-index: 4;

      h1 {
        font-size: 40px;
        text-align: center;

        span {
          color: $primary-color;
        }
      }
      p {
        font-size: 18px;
        line-height: 26px;
        text-indent: 30px;
      }
      .hero-button {
        display: flex;
        gap: 20px;
        align-items: center;
      }
    }
    .patterns {
      position: absolute;
      right: 0;
      top: 40%;
    }
  }
}

.specialties {
  background-color: rgba(245, 250, 255, 1);

  ul {
    display: flex;
    flex-direction: column;
    gap: 20px;

    li {
      list-style-type: disc;
      font-size: 18px;
    }
  }

  .container {
    padding-block: 50px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    .top {
      display: flex;
      align-items: center;
      gap: 10px;

      h2 {
        font-size: 32px;

        span {
          color: $brown-color;
        }
      }
    }

    .cards-container {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 20px;
      justify-content: center;
      width: 100%;

      .speciality-card {
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        border-radius: 12px;
        background-color: $white-color;
        max-width: 400px;
        min-width: 300px;
        min-height: 200px;

        h1 {
          font-size: 18px;
        }

        p {
          line-height: 26px;
        }
      }
    }
  }
}

.services {
  background-color: rgba(245, 250, 255, 1);
  padding-block: 70px;
  .container {
    display: flex;
    justify-content: space-between;
    gap: 50px;

    .first-content {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 50%;

      .top {
        display: flex;
        align-items: center;
        gap: 10px;

        h2 {
          font-size: 32px;

          span {
            color: $brown-color;
          }
        }
      }

      p {
        font-size: 18px;
        line-height: 26px;
      }
    }
    .services-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      column-gap: 30px;
      width: 50%;

      h4 {
        font-weight: 400;
      }
    }
  }
}

.navOpen {
  top: 100px !important;
}

.hidden {
  display: none !important;
}

@media screen and (max-width: 940px) {
  .specialties {
    .container {
      .cards-container {
        .speciality-card {
          max-width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .navigation {
    nav {
      .hamburger-container {
        display: flex;
      }

      > ul {
        position: fixed;
        width: 100%;
        right: 0;
        left: 0;
        top: -1000px;
        transition: all 0.4s linear;
        height: fit-content;
        background-color: $white-color;
        flex-direction: column;
        gap: 0;
        padding-block: 20px;

        li {
          width: 100%;
          .link {
            width: 100%;
            color: $black-color;
            width: 100%;
            display: flex;
            align-items: center;
            padding: 20px;
            justify-content: flex-start;
          }
        }
      }
    }
  }
  .home-hero {
    padding-top: 100px;
    .container {
      .patterns {
        opacity: 0.3;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .home-hero {
    .container {
      .patterns {
        display: none;
      }
    }
  }
  .services {
    .container {
      flex-direction: column;

      .first-content {
        width: 100%;
      }
      .services-container {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .home-hero {
    padding-top: 100px;
    .container {
      .patterns {
        display: none;
      }
    }
  }
  .services {
    .container {
      flex-direction: column;

      .services-container {
        grid-template-columns: 1fr;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .home-hero {
    .container {
      .content {
        .hero-button {
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }
  }
}
