@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-optical-sizing: auto;
}

.container {
  max-width: 1500px;
  margin: 0px auto;
  width: 100%;
}

.scrolled {
  background-color: #ffffff; /* Change the background color as desired */
  /* Add other styles for the scrolled state */
  transition: background-color 0.05s ease-in-out;
}

.black-text {
  color: #000000 !important;
  text-decoration: none;
}

.padding-container {
  padding-inline: 100px;
}

body {
  font-family: "Inter", sans-serif;
}

.link {
  text-decoration: none;
}

li {
  list-style-type: none;
}

.blueBgBtn .blue-btn {
  display: block;
  padding: 16px 24px;
  background-color: rgb(42, 129, 232);
  color: #ffffff;
  width: -moz-fit-content;
  width: fit-content;
}

.maroneBgBtn .marone-btn {
  display: block;
  padding: 16px 24px;
  background-color: rgb(70, 18, 27);
  color: #ffffff;
  width: -moz-fit-content;
  width: fit-content;
}

.borderBtn .border-btn {
  display: block;
  padding: 16px 24px;
  border: 1px solid #ffffff;
  color: #ffffff;
  width: -moz-fit-content;
  width: fit-content;
}

.patient-info {
  position: relative;
  display: flex;
  gap: 5px;
  align-items: center;
}
.patient-info #down-arrow {
  transition: all 0.4s ease;
}
.patient-info:hover .patient-info-popup {
  display: flex;
}
.patient-info:hover #down-arrow {
  transform: rotate(180deg);
}
.patient-info .patient-info-popup {
  transition: all 0.4s ease;
  top: 20px;
  position: absolute;
  padding-top: 20px;
  flex-direction: column;
  width: 200px;
  background-color: white;
  display: none;
}
.patient-info .patient-info-popup .patient-info-popup-link {
  text-decoration: none;
  color: black;
  padding: 10px 10px;
}
.patient-info .patient-info-popup .patient-info-popup-link:hover {
  background-color: rgba(42, 129, 232, 0.2);
}

.general-nav {
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
  background-color: #ffffff;
}
.general-nav nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-block: 20px;
}
.general-nav nav .logo {
  display: flex;
  align-items: center;
  gap: 20px;
}
.general-nav nav .logo img {
  height: 70px;
  width: 70px;
}
.general-nav nav .logo h1 {
  text-transform: uppercase;
  font-size: 16px;
  color: #000000;
}
.general-nav nav ul {
  display: flex;
  gap: 20px;
  align-items: center;
}
.general-nav nav ul li {
  width: -moz-fit-content;
  width: fit-content;
}
.general-nav nav ul li .link {
  color: #000000;
  display: flex;
  align-items: center;
  font-size: 15px;
  transition: 0.3s ease;
  width: -moz-fit-content;
  width: fit-content;
}
.general-nav nav ul li .link:hover {
  color: rgb(42, 129, 232);
}
.general-nav nav ul li .clinic {
  border-radius: 20px;
  background-color: rgb(42, 129, 232);
  padding: 15px 20px;
  color: #ffffff;
  text-decoration: none;
  transition: 0.3s ease;
  display: block;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: 10px;
}
.general-nav nav ul li .clinic:hover {
  padding: 20px;
}
.general-nav nav .hamburger-container,
.general-nav nav .close-hamburger-container {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  display: none;
}
.general-nav nav .hamburger-container .hamburger,
.general-nav nav .close-hamburger-container .hamburger {
  font-size: 40px;
  color: #000000;
}
.general-nav nav .hamburger-container .close-hamburger,
.general-nav nav .close-hamburger-container .close-hamburger {
  font-size: 40px;
  color: #000000;
}

.about-hero {
  background: url(../../public/images/about-hero.jpg);
}

.patient-info-hero {
  background: url(../../public/images/about-us-3.jpg);
}

.appointment-hero {
  background: url(../../public/images/appointment-hero.jpg);
}

.services-hero {
  background: url(../../public/images/services-hero.jpg);
}

.contact-hero {
  background-image: url(../../public/images/contact-hero.jpg);
}

.general-hero {
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 140px;
  padding-bottom: 50px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
}
.general-hero::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
}
.general-hero .container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.general-hero .container p {
  color: #ffffff;
  font-size: 40px;
  font-weight: 500;
  position: relative;
  z-index: 3;
}
.general-hero .container p span {
  color: rgb(207, 227, 250);
}

.contact-hero {
  background: url(../../public/images/contact-hero.jpg);
  background-position: top;
}

.emergency-hero {
  background: url(../../public/images/emergency-hero.jpg);
  background-position: center center;
}

.respiratory-hero {
  background: url(../../public/images/respiratory-hero.jpg);
  background-position: top center;
}

.nursing-hero {
  background: url(../../public/images/nursing-hero.jpg);
  background-position: top center;
}

.swingbed-hero {
  background: url(../../public/images/swingbed-hero.jpg);
  background-position: center center;
}

.ultrasound-hero {
  background: url(../../public/images/ultrasound-hero.jpg);
  background-position: bottom;
}

.wound-care-hero {
  background: url(../../public/images/wound-care-hero.jpg);
  background-position: center center;
}

.x-ray-hero {
  background: url(../../public/images/xray-hero.jpg);
  background-position: center center;
}

.lab-hero {
  background: url(../../public/images/lab-hero.jpg);
  background-position: center center;
}

.psychiatric-hero {
  background: url(../../public/images/psychiatric-hero.jpg);
  background-position: bottom;
}

.rehabilitation-hero {
  background: url(../../public/images/rehabilitation-hero.jpg);
  background-position: center center;
}

.case-management-hero {
  background: url(../../public/images/case-management-hero.jpg);
  background-position: bottom;
}

.about-us {
  padding-block: 70px;
}
.about-us .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}
.about-us .text-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 500px;
}
.about-us .text-content .top {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
}
.about-us .text-content .top h2 {
  font-size: 32px;
}
.about-us .text-content .top h2 span {
  color: rgb(178, 103, 11);
}
.about-us .text-content h4 {
  color: rgb(178, 103, 11);
}
.about-us .text-content > div {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.about-us .text-content p {
  font-size: 18px;
  line-height: 26px;
}
.about-us .img-container {
  display: flex;
  gap: 15px;
  height: 400px;
}
.about-us .img-container img {
  height: 100%;
  width: 100%;
  min-width: 200px;
  -o-object-fit: cover;
     object-fit: cover;
}
.about-us .img-container .first-img {
  height: 100%;
}
.about-us .img-container .col {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.about-us .img-container .col .second-img,
.about-us .img-container .col .third-img {
  height: calc(50% - 7px);
}

.testimonial {
  padding-block: 70px;
}
.testimonial .container {
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.testimonial .container .top {
  display: flex;
  align-items: center;
  gap: 15px;
}
.testimonial .container .top h2 {
  font-size: 32px;
}
.testimonial .container .top h2 span {
  color: rgb(178, 103, 11);
}
.testimonial .container .cards-container {
  display: flex;
  justify-content: space-between;
  gap: 40px;
}
.testimonial .container .cards-container .card {
  padding: 40px;
  border: 1px solid rgb(217, 217, 217);
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.testimonial .container .cards-container .card p {
  line-height: 26px;
}
.testimonial .container .cards-container .card .row {
  display: flex;
  align-items: center;
  gap: 15px;
}
.testimonial .container .cards-container .card .row .profile-container {
  border-radius: 50%;
}
.testimonial .container .cards-container .card .row .profile-container img {
  width: 50px;
  height: 50px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
}
.testimonial .container .cards-container .card .row .col {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.testimonial .container .cards-container .card .row .col span {
  color: rgb(175, 175, 175);
  font-size: 14px;
}

.appointment {
  background: url(/public/images/appointment.jpg);
  padding-block: 70px;
  min-height: 400px;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
.appointment .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 900px;
  gap: 40px;
}
.appointment .container h1 {
  font-size: 40px;
  color: #ffffff;
}

.touch-with-us {
  padding-block: 70px;
}
.touch-with-us .container {
  display: flex;
  justify-content: space-between;
  gap: 50px;
  align-items: center;
}
.touch-with-us .container .text-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 50%;
}
.touch-with-us .container .text-content p {
  line-height: 26px;
}
.touch-with-us .container .text-content > h4 span {
  color: rgb(42, 129, 232);
}
.touch-with-us .container .text-content > span {
  color: rgb(178, 103, 11);
}
.touch-with-us .container .text-content .row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.touch-with-us .container .text-content .row .col {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 50%;
}
.touch-with-us .container .text-content .row .col h4 {
  width: 100%;
  display: flex;
  gap: 15px;
}
.touch-with-us .container .text-content .row h4 {
  width: 50%;
}
.touch-with-us .container .text-content .title {
  display: flex;
  gap: 15px;
  align-items: center;
}
.touch-with-us .container .text-content .title h2 {
  font-size: 32px;
}
.touch-with-us .container .text-content .title h2 span {
  color: rgb(178, 103, 11);
}
.touch-with-us .container .text-content .map-container {
  width: 100%;
  height: 200px;
}
.touch-with-us .container .text-content .map-container .map {
  border: 0;
  width: 100%;
  height: 100%;
}
.touch-with-us .container .form-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 50%;
}
.touch-with-us .container .form-container textarea {
  resize: none;
}
.touch-with-us .container .form-container h4 {
  color: #4f4e4e;
}
.touch-with-us .container .form-container p {
  line-height: 26px;
}
.touch-with-us .container .form-container h3 {
  color: rgb(42, 129, 232);
  font-size: 24px;
}
.touch-with-us .container .form-container form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.touch-with-us .container .form-container form input,
.touch-with-us .container .form-container form textarea {
  padding: 15px 35px;
  display: block;
  font-size: 16px;
  font-family: "Inter", sans-serif;
}
.touch-with-us .container .form-container form .full-name {
  width: 100%;
}
.touch-with-us .container .form-container form .row {
  display: flex;
  gap: 20px;
}
.touch-with-us .container .form-container form .row input {
  width: 50%;
}
.touch-with-us .container .form-container form button {
  width: -moz-fit-content;
  width: fit-content;
  padding: 15px 35px;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  border: 0;
  border-radius: 5px;
  background-color: rgb(42, 129, 232);
  color: #ffffff;
  cursor: pointer;
}

.team {
  padding-block: 70px;
}
.team .container {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.team .container .top-section {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.team .container .top-section .top {
  display: flex;
  align-items: center;
  gap: 10px;
}
.team .container .top-section .top h2 {
  font-size: 32px;
}
.team .container .top-section .top h2 span {
  color: rgb(178, 103, 11);
}
.team .container .top-section p {
  font-size: 18px;
  line-height: 26px;
  max-width: 600px;
}
.team .container .cards-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}
.team .container .popup {
  max-width: 600px;
  background-color: rgb(42, 129, 232);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 60vh;
  z-index: 99999;
  border-radius: 8px;
  padding: 30px;
  overflow: hidden;
  padding-bottom: 50px;
  padding-top: 50px;
}
.team .container .popup .x-icon {
  font-size: 40px;
  color: #ffffff;
  position: absolute;
  top: 15px;
  right: 20px;
  cursor: pointer;
}
.team .container .popup > p {
  color: #ffffff;
  height: 250px;
  overflow-y: auto;
  width: 100%;
}
.team .container .popup > p::-webkit-scrollbar {
  width: 5px;
}
.team .container .popup > p::-webkit-scrollbar-track {
  background: rgb(42, 129, 232);
}
.team .container .popup > p::-webkit-scrollbar-thumb {
  background: #b9b9b9;
}
.team .container .popup > p::-webkit-scrollbar-thumb:hover {
  background: rgb(42, 129, 232);
}
.team .container .popup-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99998;
  background-color: rgba(0, 0, 0, 0.378);
  cursor: pointer;
}

.team-card {
  position: relative;
  width: 100%;
  height: 450px;
  cursor: pointer;
  overflow: hidden;
  border-radius: 8px;
}
.team-card:hover .team-card-overlay {
  top: 0;
}
.team-card:hover .info {
  bottom: -200px;
}
.team-card:hover .info h4 {
  font-size: 20px;
  font-weight: 500;
}
.team-card:hover .info p {
  font-weight: 600;
  color: rgb(42, 129, 232);
  font-size: 14px;
  text-transform: uppercase;
}
.team-card .team-card-overlay {
  position: absolute;
  z-index: 4;
  background-color: rgba(42, 128, 232, 0.672);
  top: 480px;
  height: 100%;
  left: 0;
  right: 0;
  width: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px;
  gap: 20px;
  transition: all 0.4s ease;
}
.team-card .team-card-overlay > p {
  color: #ffffff;
  font-size: 16px;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  position: relative;
}
.team-card .team-card-overlay .links {
  display: flex;
  gap: 15px;
}
.team-card .team-card-overlay .links .read-more {
  outline: none;
  border: none;
  padding: 12px 28px;
  border-radius: 8px;
  color: rgb(42, 129, 232);
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 14px;
  cursor: pointer;
}
.team-card .team-card-overlay .links .linkedin-icon-container {
  display: block;
}
.team-card .team-card-overlay .links .linkedin-icon-container .linkedin-icon {
  font-size: 36px;
  color: #ffffff;
  height: 100%;
}
.team-card > img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 8px;
}
.team-card .info {
  position: absolute;
  z-index: 3;
  background-color: rgb(245, 250, 255);
  width: 100%;
  padding: 16px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 0px 0px 8px 8px;
  transition: all 0.4s ease;
}
.team-card .info h4 {
  font-size: 20px;
  font-weight: 500;
}
.team-card .info p {
  font-weight: 600;
  color: rgb(42, 129, 232);
  font-size: 14px;
  text-transform: uppercase;
}

.blogs {
  padding-block: 70px;
  background-color: rgb(245, 250, 255);
}
.blogs .container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.blogs .container .top {
  display: flex;
  align-items: center;
  gap: 10px;
}
.blogs .container .top h2 {
  font-size: 32px;
}
.blogs .container .top h2 span {
  color: rgb(178, 103, 11);
}
.blogs .container .card-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

.blog-card {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 15px;
  padding-bottom: 25px;
  background-color: #ffffff;
  border-radius: 8px;
}
.blog-card .img-container {
  position: relative;
}
.blog-card .img-container img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
}
.blog-card .img-container span {
  position: absolute;
  background-color: rgb(42, 129, 232);
  padding: 8px 10px;
  color: #ffffff;
  border-radius: 8px;
  bottom: 10px;
  right: 10px;
}
.blog-card h4 {
  font-size: 18px;
  line-height: 26px;
}
.blog-card p {
  font-size: 16px;
  color: rgb(137, 137, 137);
}
.blog-card .link {
  color: rgb(42, 129, 232);
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 18px;
  font-weight: 500;
}
.blog-card .link .icon {
  font-size: 20px;
}

.services .container .main {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}
.services .container .main .service-link {
  text-decoration: none;
  color: #000000;
  transition: 0.4s ease;
  padding: 20px;
}
.services .container .main .service-link:hover {
  box-shadow: 0px 0px 50px -15px rgba(0, 0, 0, 0.4);
}

.service-card {
  display: flex;
  gap: 15px;
}
.service-card .icon {
  background-color: rgb(42, 129, 232);
  padding: 10px;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.service-card .col {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.service-card .col h4 {
  font-size: 18px;
}
.service-card .col p {
  line-height: 26px;
}

footer {
  padding-top: 70px;
  padding-bottom: 30px;
  background-color: rgb(70, 18, 27);
}
footer .container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  color: #ffffff;
}
footer .container .top {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  padding-bottom: 40px;
  align-items: center;
}
footer .container .top .first {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
footer .container .top .first .logo {
  display: flex;
  align-items: center;
  gap: 20px;
}
footer .container .top .first .logo h1 {
  text-transform: uppercase;
  font-size: 16px;
  color: #ffffff;
}
footer .container .top .first .socials {
  display: flex;
  gap: 20px;
  justify-content: center;
}
footer .container .top .first .socials .icon {
  color: #ffffff;
  font-size: 30px;
}
footer .container .top .second {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
footer .container .top .second .link {
  color: #ffffff;
}
footer .container .top .third p,
footer .container .top .fourth p {
  line-height: 26px;
}
footer .container .bottom {
  display: flex;
  justify-content: space-between;
  gap: 50px;
  align-items: center;
}
footer .container .bottom .last {
  display: flex;
  gap: 20px;
  align-items: center;
}
footer .container .bottom .last .link {
  color: #ffffff;
}

.policies .container .policies-row {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}
.policies .container .policies-row .cohesive-logo {
  width: 360px;
  height: 440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 25px;
  justify-content: center;
  gap: 1rem;
  background-color: #292464;
}
.policies .container .policies-row .cohesive-logo h2 {
  color: #ffffff;
  display: block;
  text-align: center;
  font-size: 26px;
}
.policies .container .policies-row .policy {
  width: 360px;
  height: 440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 32px;
  gap: 1rem;
  background-color: #f4f4f4;
}
.policies .container .policies-row .policy a {
  background-color: rgb(42, 129, 232);
  color: white;
  padding: 16px 24px;
  text-decoration: none;
}
.policies .container .policies-row .policy .link-btns {
  display: flex;
  gap: 24px;
}

.price-transparency .container .top {
  justify-content: flex-start !important;
}

@media screen and (max-width: 1300px) {
  .general-nav nav .hamburger-container {
    display: flex;
  }
  .general-nav nav > ul {
    position: fixed;
    width: 100%;
    right: 0;
    left: 0;
    top: -1000px;
    transition: all 0.4s linear;
    height: 100vh;
    background-color: #ffffff;
    flex-direction: column;
    padding-block: 20px;
    gap: 0;
  }
  .general-nav nav > ul li {
    width: 100%;
  }
  .general-nav nav > ul li .link {
    width: 100%;
    color: #000000;
    width: 100%;
    display: flex;
    padding: 20px;
  }
}
@media screen and (max-width: 1000px) {
  .padding-container {
    padding-inline: 60px;
  }
}
@media screen and (max-width: 900px) {
  .team .container .cards-container {
    grid-template-columns: 1fr;
  }
  .services .container .main {
    grid-template-columns: 1fr;
  }
  .about-us .container {
    flex-direction: column;
  }
  .about-us .container .text-content {
    max-width: 100%;
  }
  .about-us .container .img-container {
    width: 100%;
    justify-content: center;
  }
  .about-us .container .img-container .first-img {
    width: 50%;
  }
  .about-us .container .img-container .second-img,
  .about-us .container .img-container .third-img {
    width: 100%;
  }
  .testimonial .container .cards-container {
    flex-direction: column;
  }
  .touch-with-us .container {
    flex-direction: column;
  }
  .touch-with-us .container .text-content {
    width: 100%;
  }
  .touch-with-us .container .form-container {
    width: 100%;
  }
  .touch-with-us .container .form-container form .row {
    flex-direction: column;
  }
  .touch-with-us .container .form-container form .row input {
    width: 100%;
  }
  footer .container .top {
    flex-direction: column;
    text-align: center;
  }
  footer .container .bottom {
    flex-direction: column;
    text-align: center;
  }
  .blogs .container .card-container {
    grid-template-columns: 1fr;
  }
  .blog-card {
    border-radius: 16px;
  }
  .blog-card .img-container {
    height: 250px;
  }
}
@media screen and (max-width: 550px) {
  .padding-container {
    padding-inline: 30px;
  }
  .navigation nav .logo h1,
  .general-nav nav .logo h1 {
    font-size: 12px;
  }
  .about-us .img-container {
    height: 300px;
  }
  .about-us .img-container img {
    min-width: 130px;
  }
}/*# sourceMappingURL=main.css.map */