@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
.row {
  display: flex;
  justify-content: space-between;
}

.col {
  display: flex;
  flex-direction: column;
}

.lab-row-1 .container {
  padding-block: 50px;
  align-items: center;
  gap: 50px;
}
.lab-row-1 .container p {
  color: rgb(51, 51, 51);
  line-height: 26px;
  font-size: 18px;
  min-width: 300px;
  width: 50%;
}
.lab-row-1 .container .img-container {
  width: 50%;
}
.lab-row-1 .container .img-container img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: bottom;
     object-position: bottom;
}

.lab-services .container {
  padding-block: 50px;
  gap: 20px;
}
.lab-services .container h3 {
  font-size: 24px;
}
.lab-services .container .row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 30px;
}
.lab-services .container .row ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.lab-services .container .row ul li {
  font-size: 18px;
  vertical-align: middle;
  color: rgb(44, 43, 43);
  display: flex;
}
.lab-services .container .row ul li::before {
  display: block;
  content: "•";
  font-size: 30px;
  padding-right: 10px;
  line-height: 20px;
}

.x-ray .container,
.swingbed .container {
  padding-block: 50px;
  gap: 50px;
}
.x-ray .container .x-ray-1,
.x-ray .container .swingbed-1,
.swingbed .container .x-ray-1,
.swingbed .container .swingbed-1 {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 50%;
}
.x-ray .container .x-ray-1 h1,
.x-ray .container .swingbed-1 h1,
.swingbed .container .x-ray-1 h1,
.swingbed .container .swingbed-1 h1 {
  font-size: 24px;
}
.x-ray .container .x-ray-1 p,
.x-ray .container .swingbed-1 p,
.swingbed .container .x-ray-1 p,
.swingbed .container .swingbed-1 p {
  font-size: 18px;
  line-height: 26px;
  min-width: 300px;
}
.x-ray .container .img-container,
.swingbed .container .img-container {
  width: 50%;
}
.x-ray .container .img-container img,
.swingbed .container .img-container img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: bottom;
     object-position: bottom;
}

.ct-scan {
  background-color: rgb(248, 248, 248);
}
.ct-scan .container {
  padding-block: 50px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.ct-scan .container h2 {
  font-size: 24px;
}
.ct-scan .container p {
  line-height: 26px;
  font-size: 18px;
}

.grey-section {
  background-color: rgb(248, 248, 248);
}
.grey-section .container {
  padding-block: 30px;
}
.grey-section .container p {
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
}

.example .container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-block: 50px;
}
.example .container h1 {
  font-size: 24px;
}
.example .container .example-list {
  gap: 30px;
}
.example .container .example-list ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.example .container .example-list ul li {
  font-size: 18px;
  vertical-align: middle;
  color: rgb(44, 43, 43);
  display: flex;
}
.example .container .example-list ul li::before {
  display: block;
  content: "•";
  font-size: 30px;
  padding-right: 10px;
  line-height: 20px;
}

.expect .container {
  gap: 50px;
  padding-block: 50px;
}
.expect .container .img-container {
  width: 50%;
}
.expect .container .img-container img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.expect .container .col {
  gap: 20px;
  width: 50%;
}
.expect .container .col p {
  font-size: 18px;
  line-height: 26px;
}
.expect .container .col h1 {
  font-size: 24px;
}
.expect .container .col .row {
  gap: 30px;
}
.expect .container .col .row ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.expect .container .col .row ul li {
  font-size: 18px;
  vertical-align: middle;
  color: rgb(44, 43, 43);
  display: flex;
}
.expect .container .col .row ul li::before {
  display: block;
  content: "•";
  font-size: 30px;
  padding-right: 10px;
  line-height: 20px;
}

.emergency .container,
.psychiatric .container,
.nursing .container {
  padding-block: 50px;
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.emergency .container .col,
.psychiatric .container .col,
.nursing .container .col {
  gap: 20px;
}
.emergency .container .program-locations,
.psychiatric .container .program-locations,
.nursing .container .program-locations {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.emergency .container .program-locations ul,
.psychiatric .container .program-locations ul,
.nursing .container .program-locations ul {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-left: 12px;
}
.emergency .container .program-locations ul li,
.psychiatric .container .program-locations ul li,
.nursing .container .program-locations ul li {
  list-style-type: disc;
}
.emergency .container p,
.psychiatric .container p,
.nursing .container p {
  font-size: 18px;
  line-height: 26px;
  color: rgb(84, 84, 84);
}
.emergency .container .row,
.psychiatric .container .row,
.nursing .container .row {
  gap: 50px;
  align-items: center;
}
.emergency .container .row .img-container,
.psychiatric .container .row .img-container,
.nursing .container .row .img-container {
  width: 50%;
}
.emergency .container .row .img-container img,
.psychiatric .container .row .img-container img,
.nursing .container .row .img-container img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: top;
     object-position: top;
}
.emergency .container .row .col,
.psychiatric .container .row .col,
.nursing .container .row .col {
  gap: 30px;
  width: 50%;
  min-width: 300px;
}
.emergency .container .row .col h1,
.psychiatric .container .row .col h1,
.nursing .container .row .col h1 {
  font-size: 30px;
}

.respiratory-1 .container {
  gap: 50px;
  padding-block: 50px;
}
.respiratory-1 .container .img-container {
  width: 50%;
}
.respiratory-1 .container .img-container img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: top;
     object-position: top;
}
.respiratory-1 .container .col {
  gap: 20px;
  width: 50%;
}
.respiratory-1 .container .col p {
  font-size: 18px;
  line-height: 26px;
  color: rgb(84, 84, 84);
}
.respiratory-1 .container .col h1 {
  font-size: 30px;
}
.respiratory-1 .container .col .row {
  gap: 30px;
}
.respiratory-1 .container .col .row ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.respiratory-1 .container .col .row ul li {
  font-size: 18px;
  vertical-align: middle;
  color: rgb(44, 43, 43);
  display: flex;
}
.respiratory-1 .container .col .row ul li::before {
  display: block;
  content: "•";
  font-size: 30px;
  padding-right: 10px;
  line-height: 20px;
}

.respiratory-licensed .container,
.rehabilitation-2 .container,
.rehabilitation-5 .container {
  gap: 30px;
  padding-block: 50px;
}
.respiratory-licensed .container p,
.rehabilitation-2 .container p,
.rehabilitation-5 .container p {
  font-size: 18px;
  line-height: 26px;
  color: rgb(44, 43, 43);
}
.respiratory-licensed .container h1,
.rehabilitation-2 .container h1,
.rehabilitation-5 .container h1 {
  font-size: 30px;
}
.respiratory-licensed .container .row,
.rehabilitation-2 .container .row,
.rehabilitation-5 .container .row {
  gap: 30px;
}
.respiratory-licensed .container .row ul,
.rehabilitation-2 .container .row ul,
.rehabilitation-5 .container .row ul {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.respiratory-licensed .container .row ul li,
.rehabilitation-2 .container .row ul li,
.rehabilitation-5 .container .row ul li {
  font-size: 18px;
  vertical-align: middle;
  color: rgb(44, 43, 43);
  display: flex;
}
.respiratory-licensed .container .row ul li::before,
.rehabilitation-2 .container .row ul li::before,
.rehabilitation-5 .container .row ul li::before {
  display: block;
  content: "•";
  font-size: 30px;
  padding-right: 10px;
  line-height: 20px;
}

.ultrasound .container,
.rehabilitation-4 .container,
.rehabilitation-6 .container {
  gap: 30px;
  padding-block: 50px;
}
.ultrasound .container p,
.rehabilitation-4 .container p,
.rehabilitation-6 .container p {
  font-size: 18px;
  line-height: 26px;
  font-size: rgb(77, 76, 76);
}
.ultrasound .container > .row,
.rehabilitation-4 .container > .row,
.rehabilitation-6 .container > .row {
  gap: 50px;
  align-items: center;
}
.ultrasound .container > .row .img-container,
.rehabilitation-4 .container > .row .img-container,
.rehabilitation-6 .container > .row .img-container {
  width: 50%;
}
.ultrasound .container > .row .img-container img,
.rehabilitation-4 .container > .row .img-container img,
.rehabilitation-6 .container > .row .img-container img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: top;
     object-position: top;
}
.ultrasound .container > .row .col,
.rehabilitation-4 .container > .row .col,
.rehabilitation-6 .container > .row .col {
  gap: 20px;
  width: 50%;
}
.ultrasound .container > .row .col h1,
.rehabilitation-4 .container > .row .col h1,
.rehabilitation-6 .container > .row .col h1 {
  font-size: 30px;
}
.ultrasound .container > .row .col .row,
.rehabilitation-4 .container > .row .col .row,
.rehabilitation-6 .container > .row .col .row {
  gap: 30px;
}
.ultrasound .container > .row .col .row ul,
.rehabilitation-4 .container > .row .col .row ul,
.rehabilitation-6 .container > .row .col .row ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.ultrasound .container > .row .col .row ul li,
.rehabilitation-4 .container > .row .col .row ul li,
.rehabilitation-6 .container > .row .col .row ul li {
  font-size: 18px;
  vertical-align: middle;
  color: rgb(44, 43, 43);
  display: flex;
}
.ultrasound .container > .row .col .row ul li::before,
.rehabilitation-4 .container > .row .col .row ul li::before,
.rehabilitation-6 .container > .row .col .row ul li::before {
  display: block;
  content: "•";
  font-size: 30px;
  padding-right: 10px;
  line-height: 20px;
}

.wound-care-1 .container,
.case-management .container,
.case-management-3 .container,
.rehabilitation-1 .container {
  padding-block: 50px;
  gap: 50px;
  align-items: center;
}
.wound-care-1 .container .img-container,
.case-management .container .img-container,
.case-management-3 .container .img-container,
.rehabilitation-1 .container .img-container {
  width: 50%;
}
.wound-care-1 .container .img-container img,
.case-management .container .img-container img,
.case-management-3 .container .img-container img,
.rehabilitation-1 .container .img-container img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: top;
     object-position: top;
}
.wound-care-1 .container p,
.case-management .container p,
.case-management-3 .container p,
.rehabilitation-1 .container p {
  font-size: 18px;
  line-height: 26px;
}
.wound-care-1 .container .col,
.case-management .container .col,
.case-management-3 .container .col,
.rehabilitation-1 .container .col {
  gap: 20px;
  width: 50%;
}
.wound-care-1 .container .col h1,
.case-management .container .col h1,
.case-management-3 .container .col h1,
.rehabilitation-1 .container .col h1 {
  font-size: 30px;
}

.wound-care-2 .container,
.case-management-2 .container,
.rehabilitation-3 .container {
  gap: 30px;
  padding-block: 50px;
}
.wound-care-2 .container p,
.case-management-2 .container p,
.rehabilitation-3 .container p {
  font-size: 18px;
  line-height: 26px;
  color: rgb(77, 76, 76);
}
.wound-care-2 .container h1,
.case-management-2 .container h1,
.rehabilitation-3 .container h1 {
  font-size: 30px;
}
.wound-care-2 .container h2,
.case-management-2 .container h2,
.rehabilitation-3 .container h2 {
  font-size: 20px;
}
.wound-care-2 .container .row,
.case-management-2 .container .row,
.rehabilitation-3 .container .row {
  gap: 30px;
}
.wound-care-2 .container .row ul,
.case-management-2 .container .row ul,
.rehabilitation-3 .container .row ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.wound-care-2 .container .row ul li,
.case-management-2 .container .row ul li,
.rehabilitation-3 .container .row ul li {
  font-size: 18px;
  vertical-align: middle;
  color: rgb(44, 43, 43);
  display: flex;
}
.wound-care-2 .container .row ul li::before,
.case-management-2 .container .row ul li::before,
.rehabilitation-3 .container .row ul li::before {
  display: block;
  content: "•";
  font-size: 30px;
  padding-right: 10px;
  line-height: 20px;
}

.top {
  display: flex;
  align-items: center;
  gap: 10px;
}
.top h2 {
  font-size: 32px;
}
.top h2 span {
  color: rgb(178, 103, 11);
}

.choose-us {
  padding-block: 70px;
  min-height: 80vh;
  display: flex;
  align-items: center;
  background-color: rgb(245, 250, 255);
}
.choose-us .container {
  display: flex;
  justify-content: space-between;
  gap: 50px;
  height: 100%;
  align-items: center;
}
.choose-us .container .text-content {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.choose-us .container .text-content p {
  line-height: 26px;
  font-size: 18px;
}
.choose-us .container .img-container {
  position: relative;
  height: 560px;
  width: 560px;
}
.choose-us .container .img-container > img {
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 3;
  border-radius: 50%;
}
.choose-us .container .img-container .blue-container {
  position: absolute;
  background-color: rgb(42, 129, 232);
  height: 100%;
  width: 100%;
  left: -20px;
  top: -20px;
  border-radius: 50%;
}

@media screen and (max-width: 1100px) {
  .lab-services .container .row {
    grid-template-columns: 1fr 1fr;
  }
  .ultrasound .container > .row,
  .rehabilitation-4 .container > .row,
  .rehabilitation-6 .container > .row {
    flex-direction: column;
  }
  .ultrasound .container > .row .img-container,
  .rehabilitation-4 .container > .row .img-container,
  .rehabilitation-6 .container > .row .img-container {
    width: 100%;
  }
  .ultrasound .container > .row .img-container img,
  .rehabilitation-4 .container > .row .img-container img,
  .rehabilitation-6 .container > .row .img-container img {
    height: 350px;
  }
  .ultrasound .container > .row .col,
  .rehabilitation-4 .container > .row .col,
  .rehabilitation-6 .container > .row .col {
    width: 100%;
  }
  .expect .container {
    flex-direction: column;
  }
  .expect .container .img-container {
    width: 100%;
    height: 350px;
  }
  .expect .container .img-container img {
    -o-object-position: top;
       object-position: top;
  }
  .expect .container .col {
    width: 100%;
  }
  .emergency .container .row,
  .psychiatric .container .row,
  .nursing .container .row {
    flex-direction: column;
  }
  .emergency .container .row .img-container,
  .psychiatric .container .row .img-container,
  .nursing .container .row .img-container {
    width: 100%;
  }
  .emergency .container .row .img-container img,
  .psychiatric .container .row .img-container img,
  .nursing .container .row .img-container img {
    height: 350px;
    -o-object-position: top;
       object-position: top;
  }
  .emergency .container .row .col,
  .psychiatric .container .row .col,
  .nursing .container .row .col {
    width: 100%;
  }
  .x-ray .container,
  .swingbed .container {
    flex-direction: column;
  }
  .x-ray .container .x-ray-1,
  .x-ray .container .swingbed-1,
  .swingbed .container .x-ray-1,
  .swingbed .container .swingbed-1 {
    width: 100%;
  }
  .x-ray .container .img-container,
  .swingbed .container .img-container {
    height: 350px;
  }
  .x-ray .container .img-container img,
  .swingbed .container .img-container img {
    height: 100%;
    -o-object-position: top;
       object-position: top;
  }
}
@media screen and (max-width: 950px) {
  .lab-row-1 .container {
    flex-direction: column;
  }
  .lab-row-1 .container p {
    width: 100%;
  }
  .lab-row-1 .container .img-container {
    width: 100%;
  }
}
@media screen and (max-width: 900px) {
  .respiratory-licensed .container .row,
  .rehabilitation-2 .container .row,
  .rehabilitation-5 .container .row {
    flex-direction: column;
  }
  .respiratory-1 .container {
    flex-direction: column;
  }
  .respiratory-1 .container .img-container {
    width: 100%;
  }
  .respiratory-1 .container .img-container img {
    height: 350px;
  }
  .respiratory-1 .container .col {
    width: 100%;
  }
  .respiratory-1 .container .col .row {
    justify-content: start;
  }
  .wound-care-1 .container,
  .case-management .container,
  .case-management-3 .container,
  .rehabilitation-1 .container {
    flex-direction: column;
  }
  .wound-care-1 .container .img-container,
  .case-management .container .img-container,
  .case-management-3 .container .img-container,
  .rehabilitation-1 .container .img-container {
    width: 100%;
  }
  .wound-care-1 .container .img-container img,
  .case-management .container .img-container img,
  .case-management-3 .container .img-container img,
  .rehabilitation-1 .container .img-container img {
    height: 350px;
  }
  .wound-care-1 .container .col,
  .case-management .container .col,
  .case-management-3 .container .col,
  .rehabilitation-1 .container .col {
    width: 100%;
  }
  .wound-care-2 .container .row,
  .case-management-2 .container .row,
  .rehabilitation-3 .container .row {
    flex-direction: column;
  }
  .choose-us .container {
    flex-direction: column;
  }
  .choose-us .container .text-content {
    width: 100%;
  }
  .choose-us .container .img-container {
    width: 100%;
    max-width: 500px;
    height: 500px;
  }
}
@media screen and (max-width: 600px) {
  .lab-services .container .row {
    grid-template-columns: 1fr;
  }
  .ultrasound .container > .row,
  .rehabilitation-4 .container > .row,
  .rehabilitation-6 .container > .row {
    flex-direction: column;
  }
  .ultrasound .container > .row .img-container,
  .rehabilitation-4 .container > .row .img-container,
  .rehabilitation-6 .container > .row .img-container {
    width: 100%;
  }
  .ultrasound .container > .row .img-container img,
  .rehabilitation-4 .container > .row .img-container img,
  .rehabilitation-6 .container > .row .img-container img {
    height: 350px;
  }
  .ultrasound .container > .row .col,
  .rehabilitation-4 .container > .row .col,
  .rehabilitation-6 .container > .row .col {
    width: 100%;
  }
  .ultrasound .container > .row .col .row,
  .rehabilitation-4 .container > .row .col .row,
  .rehabilitation-6 .container > .row .col .row {
    flex-direction: column;
  }
  .expect .container .col .row {
    flex-direction: column;
  }
  .example .container .example-list {
    flex-direction: column;
  }
}
@media screen and (max-width: 500px) {
  .choose-us .container .img-container {
    height: 400px;
  }
}
@media screen and (max-width: 400px) {
  .choose-us .container .img-container {
    height: 300px;
  }
}/*# sourceMappingURL=services.css.map */