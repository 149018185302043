@import "../variables";

.about-us-container {
  .about-us {
    .text-content {
      .blueBgBtn {
        .blue-btn {
          display: none;
        }
      }
    }
  }
}

.ceo {
  .container {
    padding-block: 50px;
    display: flex;
    justify-content: space-between;
    gap: 50px;
    flex-direction: column;

    .main-ceo-content {
      display: flex;
      gap: 30px;
      justify-content: space-between;
      align-items: start;

      .img-container {
        width: 50%;
        img {
          width: 100%;
          height: 500px;
          object-fit: contain;
          object-position: bottom;
        }
      }
      .right-content {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 50%;

        p {
          line-height: 24px;
          font-size: 18px;
          color: rgba(79, 79, 79, 1);
        }
      }
    }
    .top {
      display: flex;
      align-items: center;
      gap: 10px;
      justify-content: center;

      h2 {
        font-size: 32px;

        span {
          color: $brown-color;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .ceo {
    .container {
      .main-ceo-content {
        flex-direction: column-reverse;
        .img-container {
          width: 100%;
          height: 500px;
          img {
            height: 100%;
          }
        }

        .right-content {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .ceo {
    .container {
      .img-container {
        height: 100%;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .ceo {
    .container {
      .main-ceo-content {
        .img-container {
          width: 100%;
          height: fit-content;
        }

        .right-content {
          width: 100%;
        }
      }
    }
  }
}
