@import "../variables";

.row {
  display: flex;
  justify-content: space-between;
}

.col {
  display: flex;
  flex-direction: column;
}

.lab-row-1 {
  .container {
    padding-block: 50px;
    align-items: center;
    gap: 50px;

    p {
      color: rgba(51, 51, 51, 1);
      line-height: 26px;
      font-size: 18px;
      min-width: 300px;
      width: 50%;
    }

    .img-container {
      width: 50%;
      img {
        width: 100%;

        object-fit: cover;
        object-position: bottom;
      }
    }
  }
}

.lab-services {
  .container {
    padding-block: 50px;
    gap: 20px;
    h3 {
      font-size: 24px;
    }
    .row {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 30px;
      ul {
        display: flex;
        flex-direction: column;
        gap: 10px;
        li {
          font-size: 18px;
          vertical-align: middle;
          color: rgba(44, 43, 43, 1);
          display: flex;
          &::before {
            display: block;
            content: "\2022";
            font-size: 30px;
            padding-right: 10px;
            line-height: 20px;
            // line-height: 20px;
          }
        }
      }
    }
  }
}

.x-ray,
.swingbed {
  .container {
    padding-block: 50px;
    gap: 50px;
    .x-ray-1,
    .swingbed-1 {
      display: flex;
      flex-direction: column;
      gap: 30px;
      width: 50%;

      h1 {
        font-size: 24px;
      }
      p {
        font-size: 18px;
        line-height: 26px;
        min-width: 300px;
      }
    }
    .img-container {
      width: 50%;
      img {
        width: 100%;
        object-fit: cover;
        object-position: bottom;
      }
    }
  }
}

.ct-scan {
  background-color: rgba(248, 248, 248, 1);
  .container {
    padding-block: 50px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    h2 {
      font-size: 24px;
    }
    p {
      line-height: 26px;
      font-size: 18px;
    }
  }
}
.grey-section {
  background-color: rgba(248, 248, 248, 1);
  .container {
    padding-block: 30px;
    p {
      font-weight: bold;
      font-size: 24px;
      line-height: 34px;
    }
  }
}

.example {
  .container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-block: 50px;

    h1 {
      font-size: 24px;
    }

    .example-list {
      gap: 30px;
      ul {
        display: flex;
        flex-direction: column;
        gap: 10px;
        li {
          font-size: 18px;
          vertical-align: middle;
          color: rgba(44, 43, 43, 1);
          display: flex;
          &::before {
            display: block;
            content: "\2022";
            font-size: 30px;
            padding-right: 10px;
            line-height: 20px;
            // line-height: 20px;
          }
        }
      }
    }
  }
}
.expect {
  .container {
    gap: 50px;
    padding-block: 50px;
    .img-container {
      width: 50%;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .col {
      gap: 20px;
      width: 50%;

      p {
        font-size: 18px;
        line-height: 26px;
      }
      h1 {
        font-size: 24px;
      }

      .row {
        gap: 30px;
        ul {
          display: flex;
          flex-direction: column;
          gap: 10px;
          li {
            font-size: 18px;
            vertical-align: middle;
            color: rgba(44, 43, 43, 1);
            display: flex;
            &::before {
              display: block;
              content: "\2022";
              font-size: 30px;
              padding-right: 10px;
              line-height: 20px;
              // line-height: 20px;
            }
          }
        }
      }
    }
  }
}
.emergency,
.psychiatric,
.nursing {
  .container {
    padding-block: 50px;
    display: flex;
    flex-direction: column;
    gap: 50px;

    .col {
      gap: 20px;
    }

    .program-locations {
      display: flex;
      flex-direction: column;
      gap: 12px;

      ul {
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding-left: 12px;

        li {
          list-style-type: disc;
        }
      }
    }

    p {
      font-size: 18px;
      line-height: 26px;
      color: rgba(84, 84, 84, 1);
    }

    .row {
      gap: 50px;
      align-items: center;

      .img-container {
        width: 50%;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: top;
        }
      }
      .col {
        gap: 30px;
        width: 50%;
        min-width: 300px;
        h1 {
          font-size: 30px;
        }
      }
    }
  }
}

.respiratory-1 {
  .container {
    gap: 50px;
    padding-block: 50px;

    .img-container {
      width: 50%;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: top;
      }
    }

    .col {
      gap: 20px;
      width: 50%;

      p {
        font-size: 18px;
        line-height: 26px;
        color: rgba(84, 84, 84, 1);
      }

      h1 {
        font-size: 30px;
      }

      .row {
        gap: 30px;
        ul {
          display: flex;
          flex-direction: column;
          gap: 10px;
          li {
            font-size: 18px;
            vertical-align: middle;
            color: rgba(44, 43, 43, 1);
            display: flex;
            &::before {
              display: block;
              content: "\2022";
              font-size: 30px;
              padding-right: 10px;
              line-height: 20px;
              // line-height: 20px;
            }
          }
        }
      }
    }
  }
}

.respiratory-licensed,
.rehabilitation-2,
.rehabilitation-5 {
  .container {
    gap: 30px;
    padding-block: 50px;

    p {
      font-size: 18px;
      line-height: 26px;
      color: rgba(44, 43, 43, 1);
    }

    h1 {
      font-size: 30px;
    }

    .row {
      gap: 30px;
      ul {
        display: flex;
        flex-direction: column;
        gap: 20px;
        li {
          font-size: 18px;
          vertical-align: middle;
          color: rgba(44, 43, 43, 1);
          display: flex;
          &::before {
            display: block;
            content: "\2022";
            font-size: 30px;
            padding-right: 10px;
            line-height: 20px;
            // line-height: 20px;
          }
        }
      }
    }
  }
}
.ultrasound,
.rehabilitation-4,
.rehabilitation-6 {
  .container {
    gap: 30px;
    padding-block: 50px;

    p {
      font-size: 18px;
      line-height: 26px;
      font-size: rgba(77, 76, 76, 1);
    }

    > .row {
      gap: 50px;
      align-items: center;

      .img-container {
        width: 50%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: top;
        }
      }

      .col {
        gap: 20px;
        width: 50%;

        h1 {
          font-size: 30px;
        }

        .row {
          gap: 30px;
          ul {
            display: flex;
            flex-direction: column;
            gap: 10px;
            li {
              font-size: 18px;
              vertical-align: middle;
              color: rgba(44, 43, 43, 1);
              display: flex;
              &::before {
                display: block;
                content: "\2022";
                font-size: 30px;
                padding-right: 10px;
                line-height: 20px;
                // line-height: 20px;
              }
            }
          }
        }
      }
    }
  }
}
.wound-care-1,
.case-management,
.case-management-3,
.rehabilitation-1 {
  .container {
    padding-block: 50px;
    gap: 50px;
    align-items: center;

    .img-container {
      width: 50%;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: top;
      }
    }

    p {
      font-size: 18px;
      line-height: 26px;
    }

    .col {
      gap: 20px;
      width: 50%;

      h1 {
        font-size: 30px;
      }
    }
  }
}

.wound-care-2,
.case-management-2,
.rehabilitation-3 {
  .container {
    gap: 30px;
    padding-block: 50px;

    p {
      font-size: 18px;
      line-height: 26px;
      color: rgba(77, 76, 76, 1);
    }

    h1 {
      font-size: 30px;
    }
    h2 {
      font-size: 20px;
    }
    .row {
      gap: 30px;
      ul {
        display: flex;
        flex-direction: column;
        gap: 10px;
        li {
          font-size: 18px;
          vertical-align: middle;
          color: rgba(44, 43, 43, 1);
          display: flex;
          &::before {
            display: block;
            content: "\2022";
            font-size: 30px;
            padding-right: 10px;
            line-height: 20px;
            // line-height: 20px;
          }
        }
      }
    }
  }
}
.top {
  display: flex;
  align-items: center;
  gap: 10px;

  h2 {
    font-size: 32px;

    span {
      color: $brown-color;
    }
  }
}

.choose-us {
  padding-block: 70px;
  min-height: 80vh;
  display: flex;
  align-items: center;
  background-color: rgba(245, 250, 255, 1);
  .container {
    display: flex;
    justify-content: space-between;
    gap: 50px;
    height: 100%;
    align-items: center;

    .text-content {
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 20px;

      p {
        line-height: 26px;
        font-size: 18px;
      }
    }

    .img-container {
      position: relative;
      height: 560px;
      width: 560px;
      > img {
        object-fit: cover;
        height: 100%;
        width: 100%;
        position: relative;
        z-index: 3;
        border-radius: 50%;
      }

      .blue-container {
        position: absolute;
        background-color: $primary-color;
        height: 100%;
        width: 100%;
        left: -20px;
        top: -20px;
        border-radius: 50%;
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .lab-services {
    .container {
      .row {
        grid-template-columns: 1fr 1fr;
      }
    }
  }

  .ultrasound,
  .rehabilitation-4,
  .rehabilitation-6 {
    .container {
      > .row {
        flex-direction: column;

        .img-container {
          width: 100%;
          img {
            height: 350px;
          }
        }

        .col {
          width: 100%;
        }
      }
    }
  }

  .expect {
    .container {
      flex-direction: column;

      .img-container {
        width: 100%;
        height: 350px;

        img {
          object-position: top;
        }
      }
      .col {
        width: 100%;
      }
    }
  }
  .emergency,
  .psychiatric,
  .nursing {
    .container {
      .row {
        flex-direction: column;

        .img-container {
          width: 100%;
          img {
            height: 350px;
            object-position: top;
          }
        }
        .col {
          width: 100%;
        }
      }
    }
  }

  .x-ray,
  .swingbed {
    .container {
      flex-direction: column;
      .x-ray-1,
      .swingbed-1 {
        width: 100%;
      }

      .img-container {
        height: 350px;

        img {
          height: 100%;
          object-position: top;
        }
      }
    }
  }
}

@media screen and (max-width: 950px) {
  .lab-row-1 {
    .container {
      flex-direction: column;

      p {
        width: 100%;
      }

      .img-container {
        width: 100%;
      }
    }
  }
}
@media screen and (max-width: 900px) {
  .respiratory-licensed,
  .rehabilitation-2,
  .rehabilitation-5 {
    .container {
      .row {
        flex-direction: column;
      }
    }
  }
  .respiratory-1 {
    .container {
      flex-direction: column;

      .img-container {
        width: 100%;
        img {
          height: 350px;
        }
      }

      .col {
        width: 100%;

        .row {
          justify-content: start;
        }
      }
    }
  }
  .wound-care-1,
  .case-management,
  .case-management-3,
  .rehabilitation-1 {
    .container {
      flex-direction: column;

      .img-container {
        width: 100%;
        img {
          height: 350px;
        }
      }

      .col {
        width: 100%;
      }
    }
  }

  .wound-care-2,
  .case-management-2,
  .rehabilitation-3 {
    .container {
      .row {
        flex-direction: column;
      }
    }
  }
  .choose-us {
    .container {
      flex-direction: column;

      .text-content {
        width: 100%;
      }

      .img-container {
        width: 100%;
        max-width: 500px;
        height: 500px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .lab-services {
    .container {
      .row {
        grid-template-columns: 1fr;
      }
    }
  }

  .ultrasound,
  .rehabilitation-4,
  .rehabilitation-6 {
    .container {
      > .row {
        flex-direction: column;

        .img-container {
          width: 100%;
          img {
            height: 350px;
          }
        }

        .col {
          width: 100%;

          .row {
            flex-direction: column;
          }
        }
      }
    }
  }

  .expect {
    .container {
      .col {
        .row {
          flex-direction: column;
        }
      }
    }
  }

  .example {
    .container {
      .example-list {
        flex-direction: column;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .choose-us {
    .container {
      .img-container {
        height: 400px;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .choose-us {
    .container {
      .img-container {
        height: 300px;
      }
    }
  }
}
