@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
.about-us-container .about-us .text-content .blueBgBtn .blue-btn {
  display: none;
}

.ceo .container {
  padding-block: 50px;
  display: flex;
  justify-content: space-between;
  gap: 50px;
  flex-direction: column;
}
.ceo .container .main-ceo-content {
  display: flex;
  gap: 30px;
  justify-content: space-between;
  align-items: start;
}
.ceo .container .main-ceo-content .img-container {
  width: 50%;
}
.ceo .container .main-ceo-content .img-container img {
  width: 100%;
  height: 500px;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: bottom;
     object-position: bottom;
}
.ceo .container .main-ceo-content .right-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 50%;
}
.ceo .container .main-ceo-content .right-content p {
  line-height: 24px;
  font-size: 18px;
  color: rgb(79, 79, 79);
}
.ceo .container .top {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}
.ceo .container .top h2 {
  font-size: 32px;
}
.ceo .container .top h2 span {
  color: rgb(178, 103, 11);
}

@media screen and (max-width: 900px) {
  .ceo .container .main-ceo-content {
    flex-direction: column-reverse;
  }
  .ceo .container .main-ceo-content .img-container {
    width: 100%;
    height: 500px;
  }
  .ceo .container .main-ceo-content .img-container img {
    height: 100%;
  }
  .ceo .container .main-ceo-content .right-content {
    width: 100%;
  }
}
@media screen and (max-width: 750px) {
  .ceo .container .img-container {
    height: 100%;
  }
}
@media screen and (max-width: 500px) {
  .ceo .container .main-ceo-content .img-container {
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
  }
  .ceo .container .main-ceo-content .right-content {
    width: 100%;
  }
}/*# sourceMappingURL=about.css.map */