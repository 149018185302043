@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
.navigation {
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
}
.navigation .patient-info .down-arrow {
  color: white;
}
.navigation .patient-info .patient-info-popup {
  padding-top: 0;
  top: 25px;
}
.navigation nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-block: 20px;
}
.navigation nav .logo {
  display: flex;
  align-items: center;
  gap: 20px;
  text-decoration: none;
}
.navigation nav .logo img {
  height: 70px;
  width: 70px;
}
.navigation nav .logo h1 {
  text-transform: uppercase;
  font-size: 16px;
  color: #ffffff;
}
.navigation nav ul {
  display: flex;
  gap: 20px;
  align-items: center;
}
.navigation nav ul li .link {
  color: #ffffff;
  display: flex;
  align-items: center;
  font-size: 15px;
  transition: 0.3s ease;
}
.navigation nav ul li .link:hover {
  color: rgb(42, 129, 232);
}
.navigation nav ul li .clinic {
  border-radius: 20px;
  background-color: rgb(42, 129, 232);
  padding: 15px 20px;
  color: #ffffff;
  text-decoration: none;
  transition: 0.3s ease;
  display: block;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: 10px;
}
.navigation nav ul li .clinic:hover {
  padding: 20px;
}
.navigation nav .hamburger-container,
.navigation nav .close-hamburger-container {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  display: none;
}
.navigation nav .hamburger-container .hamburger,
.navigation nav .close-hamburger-container .hamburger {
  font-size: 40px;
  color: #ffffff;
}
.navigation nav .hamburger-container .close-hamburger,
.navigation nav .close-hamburger-container .close-hamburger {
  font-size: 40px;
  color: #ffffff;
}

.home-hero {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.52), rgba(0, 0, 0, 0.53)), url(/public/images/home-hero.jpg);
  min-height: 100vh;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  position: relative;
  background-size: cover;
}
.home-hero .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.337254902);
}
.home-hero .container {
  padding-bottom: 50px;
  padding-top: 100px;
  display: flex;
  justify-content: center;
}
.home-hero .container .content {
  color: #ffffff;
  max-width: 950px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  z-index: 4;
}
.home-hero .container .content h1 {
  font-size: 40px;
  text-align: center;
}
.home-hero .container .content h1 span {
  color: rgb(42, 129, 232);
}
.home-hero .container .content p {
  font-size: 18px;
  line-height: 26px;
  text-indent: 30px;
}
.home-hero .container .content .hero-button {
  display: flex;
  gap: 20px;
  align-items: center;
}
.home-hero .container .patterns {
  position: absolute;
  right: 0;
  top: 40%;
}

.specialties {
  background-color: rgb(245, 250, 255);
}
.specialties ul {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.specialties ul li {
  list-style-type: disc;
  font-size: 18px;
}
.specialties .container {
  padding-block: 50px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.specialties .container .top {
  display: flex;
  align-items: center;
  gap: 10px;
}
.specialties .container .top h2 {
  font-size: 32px;
}
.specialties .container .top h2 span {
  color: rgb(178, 103, 11);
}
.specialties .container .cards-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  justify-content: center;
  width: 100%;
}
.specialties .container .cards-container .speciality-card {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 12px;
  background-color: #ffffff;
  max-width: 400px;
  min-width: 300px;
  min-height: 200px;
}
.specialties .container .cards-container .speciality-card h1 {
  font-size: 18px;
}
.specialties .container .cards-container .speciality-card p {
  line-height: 26px;
}

.services {
  background-color: rgb(245, 250, 255);
  padding-block: 70px;
}
.services .container {
  display: flex;
  justify-content: space-between;
  gap: 50px;
}
.services .container .first-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 50%;
}
.services .container .first-content .top {
  display: flex;
  align-items: center;
  gap: 10px;
}
.services .container .first-content .top h2 {
  font-size: 32px;
}
.services .container .first-content .top h2 span {
  color: rgb(178, 103, 11);
}
.services .container .first-content p {
  font-size: 18px;
  line-height: 26px;
}
.services .container .services-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  -moz-column-gap: 30px;
       column-gap: 30px;
  width: 50%;
}
.services .container .services-container h4 {
  font-weight: 400;
}

.navOpen {
  top: 100px !important;
}

.hidden {
  display: none !important;
}

@media screen and (max-width: 940px) {
  .specialties .container .cards-container .speciality-card {
    max-width: 100%;
  }
}
@media screen and (max-width: 1300px) {
  .navigation nav .hamburger-container {
    display: flex;
  }
  .navigation nav > ul {
    position: fixed;
    width: 100%;
    right: 0;
    left: 0;
    top: -1000px;
    transition: all 0.4s linear;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #ffffff;
    flex-direction: column;
    gap: 0;
    padding-block: 20px;
  }
  .navigation nav > ul li {
    width: 100%;
  }
  .navigation nav > ul li .link {
    width: 100%;
    color: #000000;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 20px;
    justify-content: flex-start;
  }
  .home-hero {
    padding-top: 100px;
  }
  .home-hero .container .patterns {
    opacity: 0.3;
  }
}
@media screen and (max-width: 900px) {
  .home-hero .container .patterns {
    display: none;
  }
  .services .container {
    flex-direction: column;
  }
  .services .container .first-content {
    width: 100%;
  }
  .services .container .services-container {
    width: 100%;
  }
}
@media screen and (max-width: 750px) {
  .home-hero {
    padding-top: 100px;
  }
  .home-hero .container .patterns {
    display: none;
  }
  .services .container {
    flex-direction: column;
  }
  .services .container .services-container {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 500px) {
  .home-hero .container .content .hero-button {
    flex-direction: column;
    align-items: flex-start;
  }
}/*# sourceMappingURL=home.css.map */