@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
.appointment-section {
  padding-block: 70px;
}
.appointment-section .container {
  display: flex;
  justify-content: space-between;
  gap: 50px;
}
.appointment-section .container .form-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 50%;
}
.appointment-section .container .form-container h2 {
  font-size: 32px;
}
.appointment-section .container .form-container h2 span {
  color: rgb(178, 103, 11);
}
.appointment-section .container .form-container p {
  font-size: 18px;
  line-height: 26px;
}
.appointment-section .container .form-container form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.appointment-section .container .form-container form label {
  font-size: 18px;
  color: rgb(122, 109, 109);
}
.appointment-section .container .form-container form select {
  width: 100%;
}
.appointment-section .container .form-container form input,
.appointment-section .container .form-container form select {
  padding: 16px;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  color: rgb(175, 175, 175);
}
.appointment-section .container .form-container form .col {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}
.appointment-section .container .form-container form .col input {
  width: 100%;
}
.appointment-section .container .form-container form .row {
  display: flex;
  gap: 20px;
}
.appointment-section .container .form-container form .row input {
  width: 100%;
}
.appointment-section .container .form-container form button {
  width: -moz-fit-content;
  width: fit-content;
  padding: 12px 24px;
  color: #ffffff;
  background-color: rgb(42, 129, 232);
  border: none;
  outline: none;
  font-size: 18px;
  font-weight: 500;
}
.appointment-section .container .img-container {
  width: 50%;
  height: 500px;
}
.appointment-section .container .img-container img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

@media screen and (max-width: 900px) {
  .appointment-section .container {
    flex-direction: column;
  }
  .appointment-section .container .form-container {
    width: 100%;
  }
  .appointment-section .container .form-container form .row {
    flex-direction: column;
  }
  .appointment-section .container .form-container form .row input {
    width: 100%;
  }
  .appointment-section .container .img-container {
    width: 100%;
  }
}/*# sourceMappingURL=appointment.css.map */