@import "./variables";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-optical-sizing: auto;
}

.container {
  max-width: 1500px;
  margin: 0px auto;
  width: 100%;
}

.scrolled {
  background-color: $white-color; /* Change the background color as desired */
  /* Add other styles for the scrolled state */
  transition: background-color 0.05s ease-in-out;
}

.black-text {
  color: #000000 !important;
  text-decoration: none;
}
.padding-container {
  padding-inline: 100px;
}

//Added a comment here for pull request

body {
  font-family: $font-family;
}

.link {
  text-decoration: none;
}

li {
  list-style-type: none;
}

.blueBgBtn {
  .blue-btn {
    display: block;
    padding: 16px 24px;
    background-color: $primary-color;
    color: $white-color;
    width: fit-content;
  }
}

.maroneBgBtn {
  .marone-btn {
    display: block;
    padding: 16px 24px;
    background-color: $secondary-color;
    color: $white-color;
    width: fit-content;
  }
}

.borderBtn {
  .border-btn {
    display: block;
    padding: 16px 24px;
    border: 1px solid $white-color;
    color: $white-color;
    width: fit-content;
  }
}

.patient-info {
  position: relative;
  display: flex;
  gap: 5px;
  align-items: center;

  #down-arrow {
    transition: all 0.4s ease;
  }

  &:hover {
    .patient-info-popup {
      display: flex;
    }
    #down-arrow {
      transform: rotate(180deg);
    }
  }

  .patient-info-popup {
    transition: all 0.4s ease;
    top: 20px;
    position: absolute;
    padding-top: 20px;
    flex-direction: column;
    width: 200px;

    background-color: white;
    display: none;
    .patient-info-popup-link {
      text-decoration: none;
      color: black;
      padding: 10px 10px;

      &:hover {
        background-color: rgba(42, 129, 232, 0.2);
      }
    }
  }
}

.general-nav {
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
  background-color: $white-color;

  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-block: 20px;

    .logo {
      display: flex;
      align-items: center;
      gap: 20px;

      img {
        height: 70px;
        width: 70px;
      }

      h1 {
        text-transform: uppercase;
        font-size: 16px;
        color: $black-color;
      }
    }

    ul {
      display: flex;
      gap: 20px;
      align-items: center;
      li {
        width: fit-content;
        .link {
          color: $black-color;
          display: flex;
          align-items: center;
          font-size: 15px;
          transition: 0.3s ease;
          width: fit-content;

          &:hover {
            color: $primary-color;
          }
        }

        .clinic {
          border-radius: 20px;
          background-color: $primary-color;
          padding: 15px 20px;
          color: $white-color;
          text-decoration: none;
          transition: 0.3s ease;
          display: block;
          width: fit-content;
          margin-left: 10px;

          &:hover {
            padding: 20px;
          }
        }
      }
    }
    .hamburger-container,
    .close-hamburger-container {
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      display: none;

      .hamburger {
        font-size: 40px;
        color: $black-color;
      }
      .close-hamburger {
        font-size: 40px;
        color: $black-color;
      }
    }
  }
}

.about-hero {
  background: url(../../public/images/about-hero.jpg);
}

.patient-info-hero {
  background: url(../../public/images/about-us-3.jpg);
}

.appointment-hero {
  background: url(../../public/images/appointment-hero.jpg);
}
.services-hero {
  background: url(../../public/images/services-hero.jpg);
}

.contact-hero {
  background-image: url(../../public/images/contact-hero.jpg);
}

.general-hero {
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 140px;
  padding-bottom: 50px;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #00000099;
  }
  .container {
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      color: $white-color;
      font-size: 40px;
      font-weight: 500;
      position: relative;
      z-index: 3;

      span {
        color: rgba(207, 227, 250, 1);
      }
    }
  }
}

.contact-hero {
  background: url(../../public/images/contact-hero.jpg);
  background-position: top;
}

.emergency-hero {
  background: url(../../public/images/emergency-hero.jpg);
  background-position: center center;
}
.respiratory-hero {
  background: url(../../public/images/respiratory-hero.jpg);
  background-position: top center;
}
.nursing-hero {
  background: url(../../public/images/nursing-hero.jpg);
  background-position: top center;
}

.swingbed-hero {
  background: url(../../public/images/swingbed-hero.jpg);
  background-position: center center;
}

.ultrasound-hero {
  background: url(../../public/images/ultrasound-hero.jpg);
  background-position: bottom;
}

.wound-care-hero {
  background: url(../../public/images/wound-care-hero.jpg);
  background-position: center center;
}
.x-ray-hero {
  background: url(../../public/images/xray-hero.jpg);
  background-position: center center;
}

.lab-hero {
  background: url(../../public/images/lab-hero.jpg);
  background-position: center center;
}
.psychiatric-hero {
  background: url(../../public/images/psychiatric-hero.jpg);
  background-position: bottom;
}
.rehabilitation-hero {
  background: url(../../public/images/rehabilitation-hero.jpg);
  background-position: center center;
}
.case-management-hero {
  background: url(../../public/images/case-management-hero.jpg);
  background-position: bottom;
}

.about-us {
  padding-block: 70px;
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
  }

  .text-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 500px;

    .top {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 10px;

      h2 {
        font-size: 32px;

        span {
          color: $brown-color;
        }
      }
    }

    h4 {
      color: $brown-color;
    }

    > div {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    p {
      font-size: 18px;
      line-height: 26px;
    }
  }

  .img-container {
    display: flex;
    gap: 15px;
    height: 400px;

    img {
      height: 100%;
      width: 100%;
      min-width: 200px;
      object-fit: cover;
    }

    .first-img {
      height: 100%;
    }

    .col {
      display: flex;
      flex-direction: column;
      gap: 15px;

      .second-img,
      .third-img {
        height: calc(50% - 7px);
      }
    }
  }
}

.testimonial {
  padding-block: 70px;
  .container {
    display: flex;
    flex-direction: column;
    gap: 50px;

    .top {
      display: flex;
      align-items: center;
      gap: 15px;

      h2 {
        font-size: 32px;

        span {
          color: $brown-color;
        }
      }
    }

    .cards-container {
      display: flex;
      justify-content: space-between;
      gap: 40px;

      .card {
        padding: 40px;
        border: 1px solid rgba(217, 217, 217, 1);
        display: flex;
        flex-direction: column;
        gap: 32px;

        p {
          line-height: 26px;
        }

        .row {
          display: flex;
          align-items: center;
          gap: 15px;
          .profile-container {
            border-radius: 50%;

            img {
              width: 50px;
              height: 50px;
              object-fit: cover;
              border-radius: 50%;
            }
          }

          .col {
            display: flex;
            flex-direction: column;
            gap: 5px;
            span {
              color: rgba(175, 175, 175, 1);
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

.appointment {
  background: url(/public/images/appointment.jpg);
  padding-block: 70px;
  min-height: 400px;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-width: 900px;
    gap: 40px;

    h1 {
      font-size: 40px;
      color: $white-color;
    }
  }
}

.touch-with-us {
  padding-block: 70px;
  .container {
    display: flex;
    justify-content: space-between;
    gap: 50px;
    align-items: center;

    .text-content {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 50%;
      p {
        line-height: 26px;
      }

      > h4 {
        span {
          color: $primary-color;
        }
      }

      > span {
        color: $brown-color;
      }

      .row {
        display: flex;
        justify-content: space-between;
        gap: 20px;

        .col {
          display: flex;
          flex-direction: column;
          gap: 10px;
          width: 50%;

          h4 {
            width: 100%;

            display: flex;
            gap: 15px;
          }
        }
        h4 {
          width: 50%;
        }
      }
      .title {
        display: flex;
        gap: 15px;
        align-items: center;

        h2 {
          font-size: 32px;
          span {
            color: $brown-color;
          }
        }
      }
      .map-container {
        width: 100%;
        height: 200px;

        .map {
          border: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    .form-container {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 50%;

      textarea {
        resize: none;
      }

      h4 {
        color: #4f4e4e;
      }

      p {
        line-height: 26px;
      }

      h3 {
        color: $primary-color;
        font-size: 24px;
      }

      form {
        display: flex;
        flex-direction: column;
        gap: 20px;
        input,
        textarea {
          padding: 15px 35px;
          display: block;
          font-size: 16px;
          font-family: $font-family;
        }
        .full-name {
          width: 100%;
        }

        .row {
          display: flex;
          gap: 20px;

          input {
            width: 50%;
          }
        }

        button {
          width: fit-content;
          padding: 15px 35px;
          font-size: 16px;
          font-family: $font-family;
          border: 0;
          border-radius: 5px;
          background-color: $primary-color;
          color: $white-color;
          cursor: pointer;
        }
      }
    }
  }
}

.team {
  padding-block: 70px;
  .container {
    display: flex;
    flex-direction: column;
    gap: 40px;

    .top-section {
      display: flex;
      flex-direction: column;
      gap: 15px;
      .top {
        display: flex;
        align-items: center;
        gap: 10px;

        h2 {
          font-size: 32px;

          span {
            color: $brown-color;
          }
        }
      }
      p {
        font-size: 18px;
        line-height: 26px;
        max-width: 600px;
      }
    }

    .cards-container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 20px;
    }
    .popup {
      max-width: 600px;
      background-color: $primary-color;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-height: 60vh;
      z-index: 99999;
      border-radius: 8px;
      padding: 30px;
      overflow: hidden;
      padding-bottom: 50px;
      padding-top: 50px;

      .x-icon {
        font-size: 40px;
        color: $white-color;
        position: absolute;
        top: 15px;
        right: 20px;
        cursor: pointer;
      }
      > p {
        color: $white-color;
        height: 250px;
        overflow-y: auto;
        width: 100%;

        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-track {
          background: $primary-color;
        }

        &::-webkit-scrollbar-thumb {
          background: #b9b9b9;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: $primary-color;
        }
      }
    }
    .popup-overlay {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 99998;
      background-color: rgba(0, 0, 0, 0.378);
      cursor: pointer;
    }
  }
}
.team-card {
  position: relative;
  width: 100%;
  height: 450px;
  cursor: pointer;
  overflow: hidden;
  border-radius: 8px;

  &:hover {
    .team-card-overlay {
      top: 0;
    }

    .info {
      bottom: -200px;
      h4 {
        font-size: 20px;
        font-weight: 500;
      }
      p {
        font-weight: 600;
        color: $primary-color;
        font-size: 14px;
        text-transform: uppercase;
      }
    }
  }

  .team-card-overlay {
    position: absolute;
    z-index: 4;
    background-color: rgba(42, 128, 232, 0.672);
    top: 480px;
    height: 100%;
    left: 0;
    right: 0;
    width: 100%;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
    gap: 20px;
    transition: all 0.4s ease;

    > p {
      color: $white-color;
      font-size: 16px;
      display: -webkit-box;
      max-width: 100%;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      overflow: hidden;
      position: relative;
    }

    .links {
      display: flex;
      gap: 15px;
      .read-more {
        outline: none;
        border: none;
        padding: 12px 28px;
        border-radius: 8px;
        color: $primary-color;
        font-weight: 600;
        letter-spacing: 1px;
        font-size: 14px;
        cursor: pointer;
      }
      .linkedin-icon-container {
        display: block;
        .linkedin-icon {
          font-size: 36px;
          color: $white-color;
          height: 100%;
        }
      }
    }
  }

  > img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: 8px;
  }

  .info {
    position: absolute;
    z-index: 3;
    background-color: rgba(245, 250, 255, 1);
    width: 100%;
    padding: 16px;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 0px 0px 8px 8px;
    transition: all 0.4s ease;

    h4 {
      font-size: 20px;
      font-weight: 500;
    }
    p {
      font-weight: 600;
      color: $primary-color;
      font-size: 14px;
      text-transform: uppercase;
    }
  }
}

.blogs {
  padding-block: 70px;
  background-color: rgba(245, 250, 255, 1);
  .container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    .top {
      display: flex;
      align-items: center;
      gap: 10px;

      h2 {
        font-size: 32px;

        span {
          color: $brown-color;
        }
      }
    }
    .card-container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 20px;
    }
  }
}
.blog-card {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 15px;
  padding-bottom: 25px;
  background-color: $white-color;
  border-radius: 8px;
  .img-container {
    position: relative;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    span {
      position: absolute;
      background-color: $primary-color;
      padding: 8px 10px;
      color: $white-color;
      border-radius: 8px;
      bottom: 10px;
      right: 10px;
    }
  }
  h4 {
    font-size: 18px;
    line-height: 26px;
  }
  p {
    font-size: 16px;
    color: rgba(137, 137, 137, 1);
  }

  .link {
    color: $primary-color;
    display: flex;
    align-items: center;
    gap: 20px;
    font-size: 18px;
    font-weight: 500;

    .icon {
      font-size: 20px;
    }
  }
}

.services {
  .container {
    .main {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 30px;

      .service-link {
        text-decoration: none;
        color: $black-color;
        transition: 0.4s ease;
        padding: 20px;

        &:hover {
          -webkit-box-shadow: 0px 0px 50px -15px rgba(0, 0, 0, 0.4);
          -moz-box-shadow: 0px 0px 50px -15px rgba(0, 0, 0, 0.4);
          box-shadow: 0px 0px 50px -15px rgba(0, 0, 0, 0.4);
        }
      }
    }
  }
}

.service-card {
  display: flex;
  gap: 15px;

  .icon {
    background-color: $primary-color;
    padding: 10px;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .col {
    display: flex;
    flex-direction: column;
    gap: 10px;

    h4 {
      font-size: 18px;
    }
    p {
      line-height: 26px;
    }
  }
}

footer {
  padding-top: 70px;
  padding-bottom: 30px;
  background-color: $secondary-color;

  .container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    color: $white-color;

    .top {
      display: flex;
      justify-content: space-between;
      gap: 40px;
      padding-bottom: 40px;
      align-items: center;

      .first {
        display: flex;
        flex-direction: column;
        gap: 20px;
        .logo {
          display: flex;
          align-items: center;
          gap: 20px;

          h1 {
            text-transform: uppercase;
            font-size: 16px;
            color: $white-color;
          }
        }

        .socials {
          display: flex;
          gap: 20px;
          justify-content: center;

          .icon {
            color: $white-color;
            font-size: 30px;
          }
        }
      }
      .second {
        display: flex;
        flex-direction: column;
        gap: 15px;

        .link {
          color: $white-color;
        }
      }

      .third,
      .fourth {
        p {
          line-height: 26px;
        }
      }
    }
    .bottom {
      display: flex;
      justify-content: space-between;
      gap: 50px;
      align-items: center;

      .last {
        display: flex;
        gap: 20px;
        align-items: center;
        .link {
          color: $white-color;
        }
      }
    }
  }
}

// new added styles for policies on home page
.policies {
  .container {
    .policies-row {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;

      .cohesive-logo {
        width: 360px;
        height: 440px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 80px 25px;
        justify-content: center;
        gap: 1rem;
        background-color: #292464;

        h2 {
          color: #ffffff;
          display: block;
          text-align: center;
          font-size: 26px;
        }
      }

      .policy {
        width: 360px;
        height: 440px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 80px 32px;
        gap: 1rem;
        background-color: #f4f4f4;

        a {
          background-color: rgb(42, 129, 232);
          color: white;
          padding: 16px 24px;
          text-decoration: none;
        }

        .link-btns {
          display: flex;
          gap: 24px;
        }
      }
    }
  }
}

.price-transparency {
  .container {
    .top {
      justify-content: flex-start !important;
    }
  }
}

@media screen and (max-width: 1300px) {
  .general-nav {
    nav {
      .hamburger-container {
        display: flex;
      }

      > ul {
        position: fixed;
        width: 100%;
        right: 0;
        left: 0;
        top: -1000px;
        transition: all 0.4s linear;
        height: 100vh;
        background-color: $white-color;
        flex-direction: column;
        padding-block: 20px;
        gap: 0;

        li {
          width: 100%;
          .link {
            width: 100%;
            color: $black-color;
            width: 100%;
            display: flex;
            padding: 20px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .padding-container {
    padding-inline: 60px;
  }
}

@media screen and (max-width: 900px) {
  .team {
    .container {
      .cards-container {
        grid-template-columns: 1fr;
      }
    }
  }

  .services {
    .container {
      .main {
        grid-template-columns: 1fr;
      }
    }
  }

  .about-us {
    .container {
      flex-direction: column;

      .text-content {
        max-width: 100%;
      }
      .img-container {
        width: 100%;
        justify-content: center;

        .first-img {
          width: 50%;
        }

        .second-img,
        .third-img {
          width: 100%;
        }
      }
    }
  }
  .testimonial {
    .container {
      .cards-container {
        flex-direction: column;
      }
    }
  }
  .touch-with-us {
    .container {
      flex-direction: column;
      .text-content {
        width: 100%;
      }

      .form-container {
        width: 100%;
        form {
          .row {
            flex-direction: column;

            input {
              width: 100%;
            }
          }
        }
      }
    }
  }
  footer {
    .container {
      .top {
        flex-direction: column;
        text-align: center;
      }
      .bottom {
        flex-direction: column;
        text-align: center;
      }
    }
  }

  .blogs {
    .container {
      .card-container {
        grid-template-columns: 1fr;
      }
    }
  }
  .blog-card {
    border-radius: 16px;
    .img-container {
      height: 250px;
    }
  }
}

@media screen and (max-width: 550px) {
  .padding-container {
    padding-inline: 30px;
  }

  .navigation,
  .general-nav {
    nav {
      .logo {
        h1 {
          font-size: 12px;
        }
      }
    }
  }

  .about-us {
    .img-container {
      height: 300px;
      img {
        min-width: 130px;
      }
    }
  }
}
